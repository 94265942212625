import { Box, Button, Chip, LinearProgress, Paper, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useRouteMatch } from 'react-router-dom';

import { Header } from '../../../models/tables';

import settingsStore from '../../../stores/settingsStore';
import messageStore from '../../../stores/messageStore';
import { Upload } from '../../../models/general';
import { API } from '../../../api';
import { ROUTES } from '../../../parameters/routes';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import ProjectItem from '../../../components/UI/projects-item/ProjectsItem';
import Map from '../../../components/UX/map/Map';
import VideoList from '../../../components/UX/video-list/VideoList';
import videoStore from '../../../stores/videoStore';
import CarchupaDialog from '../../../components/UX/dialog/CarchupaDialog';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import styles from './Project.module.scss';
import { SingleProjectData } from '../../../models/projects';
import editIcon from '../../../assets/edit-mission-icon.svg';
import Ticker from '../../../components/UI/ticker/Ticker';
import { add } from 'date-fns';
import moment from 'moment';
import authStore from '../../../stores/authStore';
import { LeaderboardDialog } from './LeaderboardDialog';
import { ProjectCardType } from '../../../stores/projectEditorStore';

type Props = RouteComponentProps;

function Project(props: Props) {
	const { history } = props;
	const [loading, setloading] = useState(true);
	const [project, setProject] = useState<SingleProjectData>();
	const [videos, setVideos] = useState<Upload[]>([]);
	// const [rows, setRows] = useState<LeaderboardWithFiltering[]>([]);
	// const [leaderboard, setleaderboard] = useState<CarchupaProjectLeaderboard>();
	const [scheduleTime, setScheduleTime] = useState({});
	const [dialogDelete, setDialogDelete] = useState(false);
	// const { selectedRowCount } = settingsStore;
	const { t, i18n } = useTranslation();
	const match = useRouteMatch<{ projectId: string }>();
	const currentProjectId = Number(match.params.projectId);
	const [isLeaderboardDialogOpen, setIsLeaderboardDialogOpen] = useState(false);
	const [missionStatus, setMissionStatus] = useState<string>('');
	// settingsStore.updateSelectedRowCount(1);
	const { adminRole } = authStore;

	const projectCards = project?.cards;
	const leaderboardCard = projectCards?.find(card => card.type === ProjectCardType.LEADERBOARD);

	const missionProgress =
		leaderboardCard && !isNaN(Number(leaderboardCard.missionProgress)) ? Number(leaderboardCard.missionProgress).toFixed(2) : '0.00';

	const remainingRewards =
		leaderboardCard && !isNaN(Number(leaderboardCard.remainingRewards)) ? Number(leaderboardCard.remainingRewards).toFixed(2) : '0.00';

	const totalPlayerEarnings =
		leaderboardCard && !isNaN(Number(leaderboardCard.totalPlayerEarnings)) ? Number(leaderboardCard.totalPlayerEarnings).toFixed(2) : '0.00';

	const budget = project && !isNaN(Number(project.budget)) ? Number(project.budget).toFixed(2) : '0.00';

	const loadProject = useCallback(async () => {
		setloading(true);
		settingsStore.updateGlobalLoading(true);

		const response = await API.getProject(currentProjectId, i18n.language);
		if (response) {
			setloading(false);
			settingsStore.updateGlobalLoading(false);
			setProject(response.data.project);
			handleTimeScheduler(response.data.project.dateStart);

			if (response.data.project.status === 3) {
				setMissionStatus('Paused');
			} else {
				if (response.data.project.dateStart < Date.now() / 1000) setMissionStatus('Active');
				if (response.data.project.dateStart > Date.now() / 1000) setMissionStatus('Scheduled');
				if (response.data.project.status === 4) setMissionStatus('Finished');
			}

			// messageStore.snackbar({
			// 	message: t('Projects loaded'),
			// 	type: 'success'
			// });
		} else {
			setloading(false);
			settingsStore.updateGlobalLoading(false);
			messageStore.snackbar({
				message: t('Failed fetching projects'),
				type: 'error'
			});
		}
	}, []);

	// const loadLeaderboard = useCallback(async () => {
	// 	const leaderboardRequest: LeaderboardRequest = {
	// 		projectID: !!currentProjectId ? currentProjectId : undefined,
	// 		countryID: undefined,
	// 		startDate: undefined,
	// 		endDate: undefined
	// 	};

	// 	const response = await API.getLeaderboardPerProjectAndCountry(leaderboardRequest);
	// 	if (response) {
	// 		messageStore.snackbar({
	// 			message: `${t('Leaderboard loaded')}`,
	// 			type: 'success'
	// 		});
	// 		setRows(response.data.leaderboard);
	// 	} else {
	// 		messageStore.snackbar({
	// 			message: `${t('Leaderboard load failed')}`,
	// 			type: 'error'
	// 		});
	// 	}
	// }, []);

	const loadVideoList = useCallback(async (projectTypeId: number) => {
		setloading(true);
		settingsStore.updateGlobalLoading(true);

		const uploadsParams = {
			status: undefined,
			page: undefined,
			limit: undefined,
			projectId: currentProjectId,
			projectTypeId: projectTypeId,
			userId: undefined,
			startDate: undefined,
			endDate: undefined
		};

		const response = await API.getUploads(uploadsParams);
		if (response) {
			setloading(false);
			settingsStore.updateGlobalLoading(false);
			setVideos(response.data.uploads);
			// messageStore.snackbar({
			//   message: t('Videos loaded'),
			//   type: 'success',
			// });
		} else {
			setloading(false);
			settingsStore.updateGlobalLoading(false);
			messageStore.snackbar({
				message: t('Failed fetching user sessions'),
				type: 'error'
			});
		}
	}, []);

	const handleButtonDelete = () => {
		setDialogDelete(true);
	};

	const handleFinishStatus = async () => {
		const isConfirmed = window.confirm('Are you sure you want to finish the project?');

		if (isConfirmed) {
			try {
				const response = await API.patchFinishProjectStatus(currentProjectId);
				setMissionStatus('Finished');
				console.log(response);
				return response;
			} catch (err: any) {
				messageStore.snackbar({
					message: err.message ? err.message : t('Failed finishing project'),
					type: 'error'
				});
			}
		}
	};
	const handlePauseProject = async () => {
		try {
			await API.pauseProject(currentProjectId);
			setMissionStatus('Paused');
		} catch (err: any) {
			messageStore.snackbar({
				message: err.message ? err.message : t('Failed pausing project'),
				type: 'error'
			});
		}
	};

	const handleExportInvasiveUploads = async () => {
		try {
			const response = await API.exportInvasiveUploads(currentProjectId);
			const linkSrc = 'data:application/csv;base64,' + response.data;
			const downloadLink = document.createElement('a');
			document.body.appendChild(downloadLink);
			downloadLink.href = linkSrc;
			downloadLink.target = '_self';
			downloadLink.download = `${project?.name}_uploads.csv`;
			downloadLink.click();
		} catch (err: any) {
			messageStore.snackbar({
				message: err.message ? err.message : t('Failed exporting uploads'),
				type: 'error'
			});
		}
	};

	const handleResumeProject = async () => {
		try {
			await API.resumeProject(currentProjectId);
			setMissionStatus('Active');
		} catch (err: any) {
			messageStore.snackbar({
				message: err.message ? err.message : t('Failed resuming project'),
				type: 'error'
			});
		}
	};

	const onCloseDialogDelete = async (status: boolean) => {
		setDialogDelete(false);
		if (status) {
			const response = await API.deleteUncollectedObjects(+currentProjectId);

			if (response) {
				window.location.reload();
			} else {
				messageStore.snackbar({
					message: t('Failed deleting project'),
					type: 'error'
				});
			}
		}
	};

	useEffect(() => {
		loadProject();

		return function cleanup() {
			settingsStore.updateSelectedRowCount(5);
		};
	}, []);

	useEffect(() => {
		loadVideoList(project?.projectTypeId);
		// loadLeaderboard();
		videoStore.updateVideos([]);
	}, [project]);

	const handleEditMissionOld = () => {
		if (project?.projectTypeId === 3) {
			history.push(ROUTES.customMissionEdit(currentProjectId));
		} else {
			history.push(ROUTES.EditProjectsInfoUrl(currentProjectId));
		}
	};

	const handleEditMission = () => {
		if (project?.projectTypeId === 3) {
			history.push(ROUTES.customMissionEdit(currentProjectId));
		} else {
			history.push(ROUTES.editProject(currentProjectId));
		}
	};

	const handleSeeMission = () => {
		history.push(ROUTES.editProject(currentProjectId));
	};

	//Schedule timer logic

	const handleTimeScheduler = (dateStart: number) => {
		const now = moment.utc();
		const projectDate = moment(dateStart * 1000);
		const timeSpan = projectDate.diff(now);
		let countDays = 24 * 60 * 60 * 1000,
			countHours = 60 * 60 * 1000,
			countMinutes = 60 * 1000,
			days = Math.floor(timeSpan / countDays),
			hours = Math.floor((timeSpan - days * countDays) / countHours),
			minutes = Math.round((timeSpan - days * countDays - hours * countHours) / countMinutes),
			seconds = Math.round((timeSpan - days * countDays - hours * countHours - minutes * countMinutes) / 1000),
			pad = (n: number) => (n < 10 ? '0' + n : n);

		if (seconds === 60) {
			minutes++;
			seconds = 0;
		}
		if (minutes === 60) {
			hours++;
			minutes = 0;
		}
		if (hours === 24) {
			days++;
			hours = 0;
		}
		setScheduleTime({
			days,
			hours: pad(hours),
			minutes: pad(minutes),
			seconds
		});
	};

	const futureDate = add(new Date(), scheduleTime);

	const handleCollectableSync = async () => {
		if (!project?.id) return;
		try {
			await API.syncCollectables(project.id);
			alert('Success');
		} catch (err) {
			console.error(err);
			alert('Sync failed');
		}
	};

	const handlePhotoMarkersSync = async () => {
		if (!project?.id) return;
		try {
			await API.syncPhotoMarkers(project.id);
			alert('Success');
		} catch (err) {
			console.error(err);
			alert('Sync failed');
		}
	};

	return (
		<>
			<SimpleLayout passedStyles={styles.root}>
				<div className={styles.missionHeader}>
					<div className={styles.headerLeft}>
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<Box sx={{ display: 'flex' }}>
								{project && <Typography variant="h1">{project?.name}</Typography>}
								{missionStatus === 'Scheduled' && (
									<Chip sx={{ marginLeft: '16px', backgroundColor: '#FFA41D', color: 'white' }} label={t('Scheduled')}></Chip>
								)}

								{missionStatus === 'Paused' && (
									<Chip sx={{ marginLeft: '16px', backgroundColor: '#4A90E2', color: 'white' }} label={t('Paused')}></Chip>
								)}
								{missionStatus === 'Active' && (
									<Chip sx={{ marginLeft: '16px', backgroundColor: '#39A88F', color: 'white' }} label={t('Active')}></Chip>
								)}

								{missionStatus === 'Finished' && (
									<Chip sx={{ marginLeft: '16px', backgroundColor: '#398DA8', color: 'white' }} label={t('Finished')}></Chip>
								)}
							</Box>
							<Box sx={{ padding: '10px', position: 'relative' }}>
								<LinearProgress
									sx={{
										padding: '5px',
										height: '25px',
										borderRadius: '4px',
										backgroundColor: '#FDE568',
										'& .MuiLinearProgress-bar': {
											backgroundColor: '#139074'
										}
									}}
									variant="determinate"
									value={missionProgress || 0}
								/>
								<Box position="absolute" top={0} left={0} bottom={0} right={0} display="flex" alignItems="center" justifyContent="center">
									<Typography variant="body1">{`Mission progress ${missionProgress}%`}</Typography>
								</Box>
							</Box>
						</Box>

						<Box>
							<Box
								sx={{
									paddingLeft: 2,
									paddingRight: 2,
									backgroundColor: '#fff',
									borderRadius: 2,
									display: 'inline-flex',
									flexDirection: 'row',
									alignItems: 'flex-start',
									gap: 1
								}}>
								<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
									{t('Budget')}:{' '}
									<Box component="span" sx={{ fontWeight: 'normal' }}>
										{budget}€
									</Box>
								</Typography>
								<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
									{t('Total player earnings')}:{' '}
									<Box component="span" sx={{ fontWeight: 'normal' }}>
										{totalPlayerEarnings}€
									</Box>
								</Typography>
								<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
									{t('Remaining budget')}:{' '}
									<Box component="span" sx={{ fontWeight: 'normal' }}>
										{remainingRewards}€
									</Box>
								</Typography>
							</Box>
						</Box>
					</div>

					<Box>
						{missionStatus === 'Scheduled' && <Ticker futureDate={futureDate} />}
						{adminRole !== 'contractAdmin' && (
							<Box display="flex" gap="12px" flexDirection="column" justifyContent="flex-end">
								<Box display="flex" gap="12px">
									<Button color="primary" variant="contained" type="submit" onClick={handleCollectableSync}>
										{t('syncCollectables')}
									</Button>
									<Button color="primary" variant="contained" type="submit" onClick={handlePhotoMarkersSync}>
										{t('syncPhotoMarkers')}
									</Button>
									<Button color="primary" variant="contained" type="submit" onClick={() => setIsLeaderboardDialogOpen(true)}>
										{t('seeLeaderboard')}
									</Button>
									{missionStatus === 'Scheduled' || missionStatus === 'Active' ? (
										<Button color="primary" variant="contained" type="submit" onClick={handleFinishStatus}>
											{t('endMission')}
										</Button>
									) : null}
									{missionStatus === 'Active' && (
										<Button color="primary" variant="contained" type="submit" onClick={handlePauseProject}>
											{t('pauseMission')}
										</Button>
									)}
									{missionStatus === 'Paused' && (
										<Button color="primary" variant="contained" type="submit" onClick={handleResumeProject}>
											{t('resumeMission')}
										</Button>
									)}
									{missionStatus === 'Scheduled' || missionStatus === 'Active' ? (
										<Button
											sx={{ '&.MuiButton-root': { border: '2px solid #139074' } }}
											startIcon={<img src={editIcon} alt={'edit icon'}></img>}
											color="primary"
											variant="outlined"
											type="submit"
											onClick={handleEditMission}>
											{t('editMission')}
										</Button>
									) : null}
									{missionStatus === 'Finished' && (
										<Button
											sx={{ '&.MuiButton-root': { border: '2px solid #139074' } }}
											color="primary"
											variant="outlined"
											type="submit"
											onClick={handleSeeMission}>
											{t('seeMission')}
										</Button>
									)}
								</Box>
								<Box display="flex" gap="12px" justifyContent="flex-end">
									{project?.projectTypeId === 4 && (
										<Box display="flex" gap="12px" justifyContent="flex-end">
											{project?.projectTypeId === 4 && (
												<Button variant="outlined" startIcon={<FileDownloadIcon />} onClick={() => handleExportInvasiveUploads()}>
													{t('Export upload data')}
												</Button>
											)}
										</Box>
									)}
								</Box>
							</Box>
						)}
					</Box>
				</div>
				{project?.projectTypeId === 1 || project?.projectTypeId === 3 ? (
					<ProjectItem
						handleButtonDelete={handleButtonDelete}
						isSingleMission
						key={currentProjectId}
						parentStyle={styles.parent}
						projectDescription={project?.description}
						projectId={currentProjectId}
						totalPlayerEarnings={totalPlayerEarnings} 
						missionProgress={missionProgress}
						projectBudget={budget}
						remainingRewards={remainingRewards}
						startDate={0}
						status={''} // Add the missing properties here
					>
						<Map projectID={currentProjectId} projects={project} />
					</ProjectItem>
				) : (
					<Map isPhoto projectID={currentProjectId} projects={project} />
				)}
				<VideoList pageSizeOptions={[1, 5, 10]} videoList={videos} {...props} />{' '}
				<CarchupaDialog open={dialogDelete} setOpen={setDialogDelete}>
					<Box sx={{ padding: '24px' }}>
						<Typography variant="h2">{t('Are you sure?')}</Typography>
						<Typography sx={{ marginTop: '16px' }} variant="subtitle1">
							{t('Are you sure you want to delete uncollected objects?')}
						</Typography>
					</Box>
					<Button
						color="primary"
						onClick={() => onCloseDialogDelete(true)}
						size="medium"
						sx={{ width: '40px', margin: '0 24px 12px auto' }}
						variant="contained">
						YES
					</Button>
				</CarchupaDialog>
				{isLeaderboardDialogOpen && project && (
					<LeaderboardDialog
						projectLeaderboard={project.projectLeaderboard}
						open={isLeaderboardDialogOpen}
						onClose={() => setIsLeaderboardDialogOpen(false)}
					/>
				)}
			</SimpleLayout>
		</>
	);
}

export default Project;
