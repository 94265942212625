import React from 'react';
import { ProjectEditorSection } from './ProjectEditorComponents';
import { observer } from 'mobx-react';
import { t } from 'i18next';
import { Box, InputAdornment, TextField } from '@mui/material';
import projectEditorStore from '../../../stores/projectEditorStore';
import { MissionType } from '../videos/Uploads';

export const ProjectBudgetEdtitor = observer(() => {
	const { project, errors } = projectEditorStore;

	return (
		<ProjectEditorSection title={t('projectEditor.projectBudget.title')} tooltip={t('projectEditor.projectBudget.tooltip')}>
			<Box sx={{ display: 'flex', flexDirection: 'row', gap: '15px', alignContent: 'space-between', flexWrap: 'wrap' }}>
				<TextField
					sx={{ maxWidth: '50%', flex: '1 1 auto' }}
					label={t('projectEditor.projectBudget.title')}
					error={!!errors?.budget}
					helperText={errors?.budget && t('projectEditor.projectBudget.budgetMustBeGreaterThanOrEqualTo1')}
					type="number"
					value={project.budget}
					onChange={e => (project.budget = Number(e.target.value))}
					InputProps={{
						startAdornment: <InputAdornment position="start">€</InputAdornment>
					}}
				/>
				{project.projectTypeId == MissionType.invasiveSpecies && (
					<TextField
						sx={{ maxWidth: '50%', flex: '1 1 auto' }}
						label={t('invasiveNewSightingLimit')} 
						error={!!errors?.newSightingLimit}
						helperText={errors?.newSightingLimit}
						type="number"
						value={project.newSightingLimit}
						onChange={e => (project.newSightingLimit = Number(e.target.value))}
						InputProps={{
							startAdornment: <InputAdornment position="start">€</InputAdornment>
						}}
					/>
				)}
			</Box>
		</ProjectEditorSection>
	);
});

