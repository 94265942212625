import { Transaction } from '@tiptap/pm/state';
import { MissionType } from '../routes/core/videos/Uploads';
import { ProjectCardType } from '../stores/projectEditorStore';
import { UserForSelect, UserList } from './user';
import { UploadData } from './video';
import { TransactionEvent } from '../../../legacy/src/types/transactions';

export type AuthToken = string;
export type AdminRole = string;

export interface StandardApiResponse {
	code: number;
	data?: any;
	message: string;
}

export interface LoginResponse {
	code: number;
	data: {
		token: AuthToken;
		user: Admin;
	};
	message: string;
}

export interface UsersResponse {
	code: number;
	data: {
		users: User[];
	};
	message: string;
}

export interface UsersForSelectResponse {
	code: number;
	data: {
		users: UserForSelect[];
	};
	message: string;
}

export interface AdminsResponse {
	code: number;
	data: {
		admins: AdminRes[];
	};
	message: string;
}

export interface UserResponse {
	code: number;
	data: {
		user: User;
	};
	message: string;
}

export interface UserListResponse {
	code: number;
	data: {
		users: UserList[];
		totalItems: number;
	};
	message: string;
}

export interface CountriesResponse {
	code: number;
	data: {
		countries: Countries[];
	};
	message: string;
}

export interface TagsResponse {
	code: number;
	data: {
		tags: Tags[];
	};
	message: string;
}

export interface ObjectsResponse {
	code: number;
	data: {
		objects: Objects[];
	};
	message: string;
}

export interface VehiclesResponse {
	code: number;
	data: {
		vehicles: Vehicles[];
	};
	message: string;
}
export interface PutResponse {
	code: number;
	data: any;
	message: string;
}

export interface LeaderboardResponse {
	data: {
		users: LeaderboardUser[];
	};
}

export interface LeaderboardWithFilteringResponse {
	data: {
		leaderboard: LeaderboardWithFiltering[];
	};
}

export interface WaypointsResponse {
	code: number;
	data: { waypoints: WaypointsData[]; videosNumber: { videosNumber?: number } };
	message: string;
}

export interface WaypointsData {
	videoID: number;
	latitude: number;
	longitude: number;
	time: string | null;
	extensions: {
		videoTimestampSeconds: number;
	};
	roadid: string | null;
	roadname_roadnumber: string | null;
	chainage: number | null;
	createdAt: Date; // map view video filter by date NOT FINISHED - creation date of the waypoint? what about the video?
	isPending?: boolean;
	isApproved?: boolean;
	isRejected?: boolean;
}

export enum Statuses {
	APPROVED = 'approved',
	INCOMPLETE = 'incomplete',
	REVIEW = 'review',
	PENDING = 'pending',
	ERRORED = 'errored',
	IN_VAISALA = 'inVaisala',
	NOT_UPLOADED = 'Not uploaded',
	NOTUPLOADED = 'notUploaded',
	REJECTED = 'rejected',
	REJECTED_WITH_PAYMENT = 'rejected with payment',
	REJECTEDWITHPAYMENT = 'rejectedWithPayment',
	MIXED = 'mixed'
}

export interface AdminRes {
	ID: number;
	username: string;
	firstName: string;
	lastName: string;
	isSuper: boolean;
	createdAt: string;
}

export interface PhotoMarkers {
	id: number;
	lat: string;
	lon: string;
	objectId: number;
	status: string;
}

export interface VideoWaypoints {
	id: number;
	lat: string;
	lon: string;
	projectTypeId: MissionType;
	status: string;
	videos: {
		id: number;
		status: string;
		coordinates: [number, number];
	}[];
}

export interface GetProjectWaypointsResponse {
	code: number;
	message: string;
	data: {
		id: number;
		lat: string;
		lon: string;
		projectTypeId: MissionType;
		photoMarkers?: PhotoMarkers[];
		videos?: VideoWaypoints[];
	};
}

export interface CSVFilesData {
	ID: number;
	importedAt: string;
	originalName: string;
}
export interface CSVFilesResponse {
	data: {
		files: CSVFilesData[];
	};
}

export interface StateResponse {
	data: {
		countries: CarchupaCountry[];
	};
}

export interface ResetResponse {
	code: number;
	data: any;
	message: string;
}

export interface PaymentsResponse {
	data: PaymentUser[];
}

export interface GetAllUserFilters {
	username?: string;
	countryId?: number;
	projectId?: number;
}

export interface PaymentsSingleUserResponse {
	data: PaymentSingleUser[] | string;
}

export interface LoginOptions {
	rememberMe: boolean;
}

export interface LoginOptions {
	rememberMe: boolean;
}

export interface CollectableCounts {
	pendingCoinCount: number;
	confirmedCoinCount: number;
	pendingStrawberryCount: number;
	confirmedStrawberryCount: number;
	pendingDiamondCount: number;
	confirmedDiamondCount: number;
	totalCollectablesCount: number;
}

export interface LeaderboardCollectable extends CollectableCounts {
	pendingCoinValue: number;
	confirmedCoinValue: number;
	pendingStrawberryValue: number;
	confirmedStrawberryValue: number;
	pendingDiamondValue: number;
	confirmedDiamondValue: number;
	pendingCherryCount: number;
	confirmedCherryValue: number;
	pendingLemonCount: number;
	confirmedLemonValue: number;
	pendingBlueberryCount: number;
	confirmedBlueberryValue: number;
}

export interface CollectablesInfo {
	coinsCount: number;
	coinsValue: number;
	blueberriesCount: number;
	blueberriesValue: number;
	cherriesCount: number;
	cherriesValue: number;
	lemonsCount: number;
	lemonsValue: number;
	strawberriesCount: number;
	strawberriesValue: number;
	diamondsCount: number;
	diamondsValue: number;
	pendingCoinValue: number;
	confirmedCoinValue: number;
	pendingStrawberryValue: number;
	confirmedStrawberryValue: number;
	pendingDiamondValue: number;
	confirmedDiamondValue: number;
	pendingCherryCount: number;
	confirmedCherryValue: number;
	pendingLemonCount: number;
	confirmedLemonValue: number;
	pendingBlueberryCount: number;
	confirmedBlueberryValue: number;
	totalCollectablesCount?: number;
}

export interface LeaderboardWithFiltering {
	ID: number;
	username: string;
	collectablesInfo: CollectablesInfo;
	totalValue?: number;
}

export interface LeaderboardRequest {
	projectID: number | undefined;
	countryID?: number | undefined;
	startDate?: number | undefined;
	endDate?: number | undefined;
}

export interface CollectablesSummaryData {
	totalCollected: number;
	totalSummary: {
		count: number;
		countCollected: number;
		markerId: number;
		name: string;
		subtotal: number;
	}[];
	totalValue: number;
}

export interface ProjectCollectablesResponse {
	code: number;
	data: ProjectCollectables;
	message: string;
}
export interface ProjectCollectablesSummaryResponse {
	code: number;
	data: CollectablesSummaryData;
	message: string;
}

export interface UserBase {
	ID: number;
	firstName: string;
	lastName: string;
	username: string;
}

export interface Admin extends UserBase {
	email: string;
	receiveOffers: false;
	residenceCountryCode: false;
	socialSecurityNumber: false;
	IBAN: string;
	role: string;
	projectID: number | null;
}

export interface Wallet {
	id: number;
	balance: string;
	all_time_gains: string;
	created_at: string;
	updated_at: string;
	user_ud: number;
}

export interface User extends UserBase {
	id: number;
	socialSecurityNumber: string;
	IBAN: string;
	swiftbic: string;
	email: string;
	taxPercentage: number;
	receiveLenseOffer: number;
	receiveHolderOffer: number;
	totalCollectablesCount: number;
	videoCount: {
		pendingVideoCount: number;
		uploadedVideoCount: number;
		recordingVideoCount: number;
		isUploadingToVaisalaVideoCount: number;
		uploadedToVaisalaVideoCount: number;
		errorVaisalaVideoCount: number;
	};
	isActive?: boolean;
	allUploadData: UploadData[];
	collectables: {
		blueberryValue: number;
		cherryValue: number;
		coinValue: number;
		confirmedBlueberryCount: number;
		confirmedCherryCount: number;
		confirmedCoinCount: number;
		confirmedDiamondCount: number;
		confirmedLemonCount: number;
		confirmedStrawberryCount: number;
		diamondValue: number;
		lemonValue: number;
		pendingBlueberryCount: number;
		pendingCherryCount: number;
		pendingCoinCount: number;
		pendingDiamondCount: number;
		pendingLemonCount: number;
		pendingStrawberryCount: number;
		strawberryValue: number;
		totalCollectablesCount: number;
	};
	lastUploadData: UploadData;
	tin: string;
	username: string;
	countryName: string;
	wallet?: Wallet;
}

export interface Countries {
	id: number;
	name: string;
	residenceCountryCode: string;
}

export interface Tags {
	id: number;
	name: string;
}

export interface PaymentsByUserIdParamsI {
	userId: string;
	page?: number;
	limit?: string;
	projectId?: number;
	startDate?: number;
	endDate?: number;
}

export interface Objects {
	ID: number;
	name: string;
	price: number | string;
	canEdit?: boolean;
}

export interface Vehicles {
	id: number;
	name: string;
}
export interface LeaderboardUser extends UserBase {
	totalCollectablesCount: number;
	collectablesInfo: LeaderboardCollectable;
	totalValue: number;
	videoCount: {
		pendingVideoCount: number;
		uploadedVideoCount: number;
	};
}

export interface PaymentUser extends UserBase {
	totalEarnings: {
		coinsValue: number;
		strawberriesValue: number;
		diamondsValue: number;
		blueberryValue: number;
		LemonValue: number;
		cherryValue: number;
	};
	totalValue: string;
	status: number;
	isPaid: string;
	rawTotalValue: number;
}

export interface PaymentSingleUser extends PaymentUser {
	pID: number;
	pTotalValue: string;
	pendDate: string;
	pstartDate: string;
	ppaidAt: string;
}

export interface CarchupaProjectAdd {
	countryID: number | null;
	name: string;
	description: string;
}

export interface ProjectCollectablesList {
	id: number;
	lat: string;
	lon: string;
	markerID: number;
	name: string;
}

export interface RemainingProjectCollectablesList {
	id: number;
	lat: string;
	lon: string;
	markerId: number;
	name: string;
}

export interface ProjectCollectables {
	availableCollectablesList: ProjectCollectablesList[];
	collectedCollectablesList: ProjectCollectablesList[];
	remainingCollectablesList: RemainingProjectCollectablesList[];
}

export interface CollectableSummary {
	count: number;
	countCollected: number;
	markerId: number;
	name: string;
	subtotal: number;
}

export interface CarchupaCountry {
	ID: number;
	name: string;
}

export interface NotificationBroadcast {
	projectID?: number;
	countryID?: number;
	userIDs?: string[];
	title: string;
	message: string;
}

export interface MapPolylineCoords {
	videoID: number;
	lat: number;
	lng: number;
}

export interface Upload {
	createdAt: number;
	fileName: string;
	sessionId: number;
	gps: string;
	id: number;
	projectName: string;
	projectType: string;
	projectTypeId: number;
	status: string;
	url: string;
	username: string;
	uploads?: Upload[]; // contains the videos that were uploaded to the same session for invasive species
}

export interface UploadsData {
	countItems: number;
	page: number;
	totalItems: number;
	uploads: Upload[];
}

export interface UploadsResponse {
	code: number;
	data: UploadsData;
	message: number;
}

export interface GetUserTransactionsResponse {
	code: number;
	data: {
		transactions: Transaction[];
	};
	message: string;
}

export interface GetTransactionEventsResponse {
	code: number;
	data: {
		transactionEvents: TransactionEvent[];
	};
	message: string;
}
export interface AppSettings {
	currentAndroidAppVersion: string;
	currentiOSAppVersion: string;
	supportedAndroidAppVersion: string;
	supportediOSAppVersion: string;
}

export interface GetAppSettingsResponse {
	data: AppSettings;
}

export interface RejectionReasonsData {
	id: number;
	description: string;
}

export interface UserListParams {
	page: number;
	limit?: number;
	country?: string;
	mission?: string;
	searchedValue?: string;
}

export interface userFromAllList {
	id: number;
	username: string;
}

export interface imageFile {
	caption: string;
	file: File;
}

export interface objectToSend {
	ID: number;
	value: string;
}

export interface oldReferenceImages {
	URL: string;
	caption: string;
}

export enum CardReferenceType {
	IMAGE = 1,
	VIDEO = 2
}
export interface cardReference {
	file: File;
	caption: string;
	cardType: ProjectCardType;
	type: CardReferenceType;
}
export interface oldCardReference {
	URL: string;
	caption: string;
	cardType: ProjectCardType;
	type: CardReferenceType;
}

// Active status I don't think is used.
// clients tend to check the start and end times for a project to determine if it is active
export enum ProjectStatus {
	'STOPPED' = 0,
	'SCHEDULED' = 1,
	'ACTIVE' = 2,
	'PAUSED' = 3,
	'FINISHED' = 4,
	'ARCHIVED' = 5
}
