import React, { useEffect, useState } from 'react';
import { Box, Chip, Grid, LinearProgress, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Button } from '@mui/material';
import styles from './ProjectsItem.module.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import blueberry from '../../../assets/blueberry.svg';
import grape from '../../../assets/grape.svg';
import strawberry from '../../../assets/strawberry.svg';
import peach from '../../../assets/peach.svg';
import banana from '../../../assets/banana.svg';
import watermelon from '../../../assets/watermelon.svg';
import lupine from '../../../assets/lupine.svg';
import giant_hogweed from '../../../assets/giant-hogweed.svg';
import himalayan_balsam from '../../../assets/himalayan-balsam.svg';
import japanese_rose from '../../../assets/japanese-rose.svg';
import japanese_knotweed from '../../../assets/japanese-knotweed.svg';
import canada_goldenrod from '../../../assets/canada_goldenrod.svg';
import false_spiraea from '../../../assets/false_spiraea.svg';
import culvert from '../../../assets/culvert.svg';
import spanish_slug from '../../../assets/spanish_slug.svg';
import trashcan from '../../../assets/trashcan.svg';
import lesser_periwinkle from '../../../assets/lesser_periwinkle.svg';
import buddleia from '../../../assets/buddleia.svg';
import parrots_feather from '../../../assets/parrots_feather.svg';
import goats_rue from '../../../assets/goats_rue.svg';
import cherry_laurel from '../../../assets/cherry_laurel.svg';

import { useGetProjectCollectablesSummaryData } from '../../../queries/data-queries/useGetProjectCollectablesSummaryData';

interface ProjectItemProps {
	projectName?: string;
	projectDescription?: string;
	handleClick?: () => void;
	parentStyle?: string;
	children?: JSX.Element;
	totalPlayerEarnings: number;
	missionProgress: number;
	projectBudget: number;
	remainingRewards: number;
	showSelectProjectButton?: boolean;
	isSingleMission?: boolean;
	handleButtonDelete?: () => void;
	projectId: number;
	startDate: number;
	status: number | string;
}

// Refactor this into other places where the chip is present.
function ProjectStatusChip(props: { status: number | string; startDate: number }) {
	const { status, startDate } = props;
	const [projectStatus, setProjectStatus] = useState('');
	const { t } = useTranslation();

	useEffect(() => {
		if (status === 4 || status === 'finished') setProjectStatus('Finished');
		else if (status === 3 || status == 'paused') setProjectStatus('Paused');
		else if (startDate < Date.now() / 1000) setProjectStatus('Active');
		else if (startDate > Date.now() / 1000) setProjectStatus('Scheduled');
	}, []);
	return (
		<div style={{ display: 'flex' }}>
			{projectStatus === 'Scheduled' && <Chip sx={{ marginLeft: '16px', backgroundColor: '#FFA41D', color: 'white' }} label={t('Scheduled')}></Chip>}
			{projectStatus === 'Active' && <Chip sx={{ marginLeft: '16px', backgroundColor: '#39A88F', color: 'white' }} label={t('Active')}></Chip>}
			{projectStatus === 'Paused' && <Chip sx={{ marginLeft: '16px', backgroundColor: '#4A90E2', color: 'white' }} label={t('Paused')}></Chip>}
			{projectStatus === 'Finished' && <Chip sx={{ marginLeft: '16px', backgroundColor: '#398DA8', color: 'white' }} label={t('Finished')}></Chip>}
		</div>
	);
}

function ProjectItem(props: ProjectItemProps) {
	const {
		projectName,
		handleClick,
		projectId,
		parentStyle,
		children,
		missionProgress,
		remainingRewards,
		totalPlayerEarnings,
		showSelectProjectButton,
		projectBudget,
		isSingleMission,
		handleButtonDelete,
		status,
		startDate
	} = props;
	const { t } = useTranslation();

	/////////////////////////////////////////////// REACT-QUERY ////////////////////////////////////////////////////////

	const { data } = useGetProjectCollectablesSummaryData(projectId);
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const linearProgressStyles = {
		height: 8,
		borderRadius: 5,
		width: '75%',
		marginRight: '10px',
		marginLeft: '10px',
		backgroundColor: '#F1F1F1'
	};

	// sum of all the collectables on a mission
	const sumOfCollectables = data?.totalSummary.reduce((acc, collectable) => acc + collectable.count, 0);
	// total value of all the collectables on a mission
	const sumOfCollectablesValue = data?.totalSummary.reduce((acc, value) => acc + value.subtotal, 0);

	const renderCollectables = data?.totalSummary.map((collectable, index) => {
		const fruitImages: Record<string, string> = {
			blueberry,
			grape,
			strawberry,
			peach,
			banana,
			watermelon,
			himalayan_balsam,
			japanese_rose,
			lupine,
			giant_hogweed,
			japanese_knotweed,
			canada_goldenrod,
			false_spiraea,
			culvert,
			spanish_slug,
			trashcan,
			lesser_periwinkle,
			buddleia,
			parrots_feather,
			goats_rue,
			cherry_laurel
		};
		return (
			<div className={styles.collectables} key={index}>
				<img className={styles.fruitImg} src={fruitImages[collectable?.name]} alt={collectable?.name} />
				<LinearProgress sx={linearProgressStyles} variant="determinate" value={(collectable.countCollected / collectable.count) * 100} />
				{collectable.countCollected} / {collectable.count}
			</div>
		);
	});

	return (
		<div className={`${styles.container} ${parentStyle}`}>
			<div>{children}</div>
			<div className={isSingleMission ? styles.contentContainer : ''}>
				<div style={{ width: isSingleMission ? '55%' : '100%' }}>
					<div className={styles.collectablesContainer}>
						{!isSingleMission && (
							<Box sx={{ display: 'flex', flexDirection: 'column' }}>
								<div className={styles.title} onClick={handleClick}>
									{projectName}
									<ProjectStatusChip status={status} startDate={startDate} />
								</div>
								<Box sx={{ padding: '10px', position: 'relative' }}>
									<LinearProgress
										sx={{
											padding: '5px',
											height: '25px',
											borderRadius: '4px',
											backgroundColor: '#FDE568',
											'& .MuiLinearProgress-bar': {
												backgroundColor: '#139074'
											}
										}}
										variant="determinate"
										value={missionProgress}
									/>
									<Box position="absolute" top={0} left={0} bottom={0} right={0} display="flex" alignItems="center" justifyContent="center">
										<Typography variant="body1">{`Mission progress ${missionProgress}%`}</Typography>
									</Box>
								</Box>
							</Box>
						)}
						{data ? <div>{renderCollectables}</div> : <div style={{ marginBottom: '20%' }}>{t('noCollectables')}</div>}
					</div>
					<Box sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
						<List>
							<ListItem divider>
								<Grid container justifyContent="space-between">
									<Grid item>
										<ListItemText primary={t('Budget')} />
									</Grid>
									<Grid item>
										<Typography variant="body1">{projectBudget}€</Typography>
									</Grid>
								</Grid>
							</ListItem>
							<ListItem divider>
								<Grid container justifyContent="space-between">
									<Grid item>
										<ListItemText primary={t('Total player earnings')}/>
									</Grid>
									<Grid item>
										<Typography variant="body1">{totalPlayerEarnings}€</Typography>
									</Grid>
								</Grid>
							</ListItem>
							<ListItem>
								<Grid container justifyContent="space-between">
									<Grid item>
										<ListItemText primary={t('Remaining budget')} />
									</Grid>
									<Grid item>
										<Typography variant="body1">{remainingRewards}€</Typography>
									</Grid>
								</Grid>
							</ListItem>
						</List>
					</Box>
				</div>
				{showSelectProjectButton && (
					<Button className={styles.button} color="primary" variant="contained" type="submit" onClick={handleClick}>
						{t('seeMission')}
					</Button>
				)}
				{isSingleMission && data && (
					<Button
						className={styles.buttonDelete}
						startIcon={<DeleteIcon />}
						variant="contained"
						color="primary"
						type="submit"
						onClick={handleButtonDelete}>
						{t('deleteUncollectedObjects')}
					</Button>
				)}
			</div>
		</div>
	);
}

export default ProjectItem;
