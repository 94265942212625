import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { API } from '../../../api';
import { UploadData } from '../../../models/video';
import VideoPlayer from '../../../components/UX/player/Player';
import authStore from '../../../stores/authStore';
import { RejectionDialog } from './RejectionDialog';
import { RejectWithPaymentDialog } from './RejectWithPaymentDialog';
import { ApproveDialog } from './ApproveDialog';
import { getObjectName } from '../../../utils';
import { SessionStatusChip } from '../videos/InvasiveSpeciesItem';
import { t } from 'i18next';

const { adminRole } = authStore;

interface ProjectMapItemDialogProps {
	isOpen: boolean;
	onClose: () => void;
	sessionUploadId: number;
}

export const VideoItemDialog = (props: ProjectMapItemDialogProps) => {
	const { isOpen, onClose, sessionUploadId } = props;
	const [item, setItem] = useState<UploadData>();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [isApproveDialogOpen, setIsApproveDialogOpen] = useState<boolean>(false);
	const [isRejectDialogOpen, setIsRejectDialogOpen] = useState<boolean>(false);
	const [isRejectWithPaymentDialogOpen, setIsRejectWithPaymentDialogOpen] = useState<boolean>(false);

	const uploadId = item?.id || 0;

	const VideoDialogContent = useMemo(() => {
		const TableHeader = (props: { title: string }) => {
			const { title } = props;
			return (
				<TableCell>
					<Typography variant="caption" color="primary" fontWeight={'900'} letterSpacing={'0.05735em'}>
						{title}
					</Typography>
				</TableCell>
			);
		};

		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				flexDirection={'column'}
				style={{
					height: '100%',
					width: '100%'
				}}>
				<Box style={{ backgroundColor: 'black' }}>
					<video
						controls
						style={{
							height: '562px',
							width: '100%',
							objectFit: 'contain',
							display: 'flex',
							alignSelf: 'center',
							margin: 'auto'
						}}>
						<source src={item?.url}></source>
					</video>
				</Box>

				<Table>
					<TableHead>
						<TableRow>
						<TableHeader title={t('videoItemDialog.sessionUploadId')} />
						<TableHeader title={t('videoItemDialog.sessionDate')} />
						<TableHeader title={t('videoItemDialog.userId')} />
						<TableHeader title={t('videoItemDialog.status')} />
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>
								<a
									style={{ textDecoration: 'none', padding: 0 }}
									href={`${process.env.PUBLIC_URL}/video/${item?.id}`}
									target="_blank"
									rel="noopener noreferrer">
									<Button
										sx={{
											textDecoration: 'underline',
											padding: 0
										}}
										variant="text">
										{item?.id}
									</Button>
								</a>
							</TableCell>
							<TableCell>
								{item?.createdAt
									? `${new Date(item.createdAt * 1000).toLocaleDateString()} ${new Date(item.createdAt * 1000).toLocaleTimeString()}`
									: 'N/A'}
							</TableCell>

							<TableCell>
								{authStore.adminRole === 'superadmin' || authStore.adminRole === 'admin' ? (
									<a
										style={{ textDecoration: 'none', padding: 0 }}
										href={`${process.env.PUBLIC_URL}/profile/${item?.uploadedByUserId}`}
										target="_blank"
										rel="noopener noreferrer">
										<Button
											sx={{
												textDecoration: 'underline',
												padding: 0
											}}
											variant="text">
											{item?.uploadedByUserId}
										</Button>
									</a>
								) : (
									<span>{item?.userId}</span>
								)}
							</TableCell>
							<TableCell>
								<SessionStatusChip sessionStatus={item?.status} />
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</Box>
		);
	}, [sessionUploadId, isLoading, item]);

	useEffect(() => {
		setIsLoading(true);
		API.getUpload(sessionUploadId)
			.then(res => {
				setItem(res.data.upload);
			})
			.catch(err => {
				console.error(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [sessionUploadId]);

	return (
		<>
			{item && (
				<Dialog
					open={isOpen}
					onClose={onClose}
					fullWidth
					maxWidth="md" // Adjusted max width
					PaperProps={{
						style: {
							height: 'auto',
							overflowY: 'auto'
						}
					}}>
					<DialogContent>
						<div style={{ maxHeight: '762px', display: 'flex', justifyContent: 'center' }}>{VideoDialogContent}</div>
					</DialogContent>
					{adminRole === 'superadmin' || adminRole === 'admin' ? (
						<DialogActions>
							<Button onClick={() => setIsApproveDialogOpen(true)}>{t('Approve')}</Button>
							<Button onClick={() => setIsRejectWithPaymentDialogOpen(true)}>{t('rejectWithPayment')}</Button>
							<Button onClick={() => setIsRejectDialogOpen(true)}>{t('Reject')}</Button>
							<Button onClick={onClose}>{t('Close')}</Button>
						</DialogActions>
					) : null}
					<RejectionDialog
						isOpen={isRejectDialogOpen}
						onClose={() => setIsRejectDialogOpen(false)}
						uploadId={uploadId}
						onItemDialogClose={() => {}}
					/>
					<RejectWithPaymentDialog
						isOpen={isRejectWithPaymentDialogOpen}
						onClose={() => setIsRejectWithPaymentDialogOpen(false)}
						uploadId={uploadId}
						onItemDialogClose={() => {}}
					/>
					<ApproveDialog
						isOpen={isApproveDialogOpen}
						onClose={() => setIsApproveDialogOpen(false)}
						uploadId={uploadId}
						onItemDialogClose={() => {}}
					/>
				</Dialog>
			)}
		</>
	);
};
