import { Box, Button, Divider, Tab, Tabs, TextField, Typography } from '@mui/material';
import { t, use } from 'i18next';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { InfoTooltip } from './InfoTooltip';
import { useDropzone } from 'react-dropzone';
import projectEditorStore, { ProjectCardType, AvailableLanguages } from '../../../stores/projectEditorStore';
import BackupIcon from '@mui/icons-material/Backup';
import { number } from 'yup';
import { CardReferenceType } from '../../../models/general';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const FileDropzone = observer((props: { cardType: ProjectCardType }) => {
	const { cardType } = props;
	const { project } = projectEditorStore;

	const onDrop = (file: any) => {
		const fileType = file[0].type;
		if (fileType.includes('image')) {
			project.newReferences.push({ file: file[0], cardType: cardType, caption: '', type: CardReferenceType.IMAGE, id: 0 });
		} else if (fileType.includes('video')) {
			project.newReferences.push({ file: file[0], cardType: cardType, caption: '', type: CardReferenceType.VIDEO, id: 0 });
		} else {
			// file not supported I guess
		}
	};

	const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false, accept: '.jpg, .png, .jpeg, .mp4' });

	return (
		<div>
			<label>{t('Reference Files')}</label>
			<div
				{...getRootProps()}
				style={{
					background: '#F9F8F2',
					border: '1px dashed #A4A4A4',
					boxSizing: 'border-box',
					borderRadius: '8px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					padding: '24px',
					cursor: 'pointer'
				}}>
				<input {...getInputProps()} />
				<BackupIcon color="primary" fontSize="large" />
				<span>{t('Click to upload or drag and drop reference files here')}</span>
			</div>
		</div>
	);
});

const ProjectEdtitorSection = (props: { sectionTitle?: string; sectionTooltip?: string; children: any }) => {
	const { sectionTitle, sectionTooltip, children } = props;

	return (
		<Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column', paddingTop: '10px' }}>
			{sectionTitle && (
				<Box>
					<Divider>
						<Typography variant="caption">{sectionTitle}</Typography>
						{sectionTooltip && <InfoTooltip title={sectionTooltip as string} />}
					</Divider>
				</Box>
			)}
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', alignContent: 'space-between' }}>{children}</Box>
		</Box>
	);
};

const ReferenceFileList = observer((props: { cardType: ProjectCardType }) => {
	const { cardType } = props;
	const { project } = projectEditorStore;

	const handleRemoveNewReferenceFile = (index: number) => {
		project.newReferences.splice(index, 1);
	};

	const handleRemoveReferenceFile = (id: number) => {
		project.references = project.references.filter((reference: any) => reference.id != id);
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
			{project.references
				.filter(reference => reference.cardType == cardType)
				.map((reference: any, index: number) => {
					return (
						<Box sx={{ display: 'flex' }}>
							{reference.type == CardReferenceType.IMAGE && (
								<img
									key={`image-${index}`}
									src={reference.URL}
									style={{
										maxHeight: '216px',
										maxWidth: '172px',
										height: '100%',
										width: '100%',
										objectFit: 'contain',
										display: 'flex',
										alignSelf: 'center'
									}}
								/>
							)}
							{reference.type == CardReferenceType.VIDEO && (
								<video
									key={`video-${index}`}
									controls
									style={{
										maxHeight: '216px',
										maxWidth: '172px',
										height: '100%',
										width: '100%',
										objectFit: 'contain',
										display: 'flex',
										alignSelf: 'center'
									}}>
									<source src={reference.URL}></source>
								</video>
							)}
							<Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '12px', width: '100%' }}>
								<Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '12px', width: '100%', gap: '10px' }}>
									<span>{reference.name}</span>
									<TextField
										value={reference.caption}
										onChange={e => {
											reference.caption = e.target.value;
										}}
									/>
									<Button
										onClick={() => handleRemoveReferenceFile(reference.id)}
										startIcon={<DeleteForeverIcon />}
										sx={{ color: '#FE5F55', width: '250px' }}
										variant="text">
										{t('projectEditor.projectReferencesEditor.removeFile')}
									</Button>
								</Box>
							</Box>
						</Box>
					);
				})}
			{project.newReferences
				.filter(reference => reference.cardType == cardType)
				.map((reference: any, index: number) => {
					return (
						<Box sx={{ display: 'flex' }}>
							{reference.type == CardReferenceType.IMAGE && (
								<img
									key={`image-${index}`}
									src={URL.createObjectURL(reference.file)}
									style={{
										maxHeight: '216px',
										maxWidth: '172px',
										height: '100%',
										width: '100%',
										objectFit: 'contain',
										display: 'flex',
										alignSelf: 'center'
									}}
								/>
							)}
							{reference.type == CardReferenceType.VIDEO && (
								<video
									key={`video-${index}`}
									controls
									style={{
										maxHeight: '216px',
										maxWidth: '172px',
										height: '100%',
										width: '100%',
										objectFit: 'contain',
										display: 'flex',
										alignSelf: 'center'
									}}>
									<source src={URL.createObjectURL(reference.file)}></source>
								</video>
							)}
							<Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '12px', width: '100%' }}>
								<Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '12px', width: '100%', gap: '10px' }}>
									<span>{reference.file.name}</span>
									<TextField
										value={reference.caption}
										onChange={e => {
											reference.caption = e.target.value;
										}}
									/>
									<Button
										onClick={() => handleRemoveNewReferenceFile(index)}
										startIcon={<DeleteForeverIcon />}
										sx={{ color: '#FE5F55', width: '250px' }}
										variant="text">
										{t('projectEditor.projectReferencesEditor.removeFile')}
									</Button>
								</Box>
							</Box>
						</Box>
					);
				})}
		</Box>
	);
});

const ProjectReferencesEdtior = observer(() => {
	const { project } = projectEditorStore;
	const [tab, setTab] = useState<number>(ProjectCardType.OBJECTIVE);

	const handleTabChange = (event: React.SyntheticEvent, value: number) => {
		setTab(value);
	};

	return (
		<ProjectEdtitorSection
			sectionTitle={t('projectEditor.projectReferencesEditor.title')}
			sectionTooltip={t('projectEditor.projectReferencesEditor.tooltip')}>
			<Box sx={{ display: 'flex', alignSelf: 'center' }}>
				<Tabs value={tab} onChange={handleTabChange}>
					<Tab value={ProjectCardType.OBJECTIVE} title="Objective" icon={<span>{t('Objective')}</span>} />
					<Tab value={ProjectCardType.METHOD} title="Method" icon={<span>{t('Method')}</span>} />
					<Tab value={ProjectCardType.EQUIPMENT} title="Equipment" icon={<span>{t('Equipment')}</span>} />
					<Tab value={ProjectCardType.CONDITIONS} title="Conditions" icon={<span>{t('Conditions')}</span>} />
					<Tab value={ProjectCardType.SAFETY} title="Safety" icon={<span>{t('Safety')}</span>} />
				</Tabs>
			</Box>

			{tab === ProjectCardType.OBJECTIVE && <FileDropzone cardType={ProjectCardType.OBJECTIVE} />}
			{tab === ProjectCardType.METHOD && <FileDropzone cardType={ProjectCardType.METHOD} />}
			{tab === ProjectCardType.EQUIPMENT && <FileDropzone cardType={ProjectCardType.EQUIPMENT} />}
			{tab === ProjectCardType.CONDITIONS && <FileDropzone cardType={ProjectCardType.CONDITIONS} />}
			{tab === ProjectCardType.SAFETY && <FileDropzone cardType={ProjectCardType.SAFETY} />}
			<ReferenceFileList cardType={tab} />
		</ProjectEdtitorSection>
	);
});

export default ProjectReferencesEdtior;
