import {
	Typography,
	TextField,
	FormLabel,
	Paper,
	Switch,
	Button,
	CircularProgress,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogContentText
} from '@mui/material';
import { t } from 'i18next';
import React, { useState, useEffect } from 'react';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import styles from './SystemAdministration.module.scss';
import { API } from '../../../api';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import semver from 'semver';
import { useForm } from 'react-hook-form';

interface AppSettings {
	currentiOSAppVersion: string;
	currentAndroidAppVersion: string;
	supportediOSAppVersion: string;
	supportedAndroidAppVersion: string;
}

const SettingCard = (props: { children: any; title: string; settingDescription: any }) => {
	const { children, title, settingDescription } = props;

	return (
		<div className={styles.settingsCardContainer}>
			<div className={styles.settingsCardDescription}>
				<Typography variant="h6">{title}</Typography>
				<span>{settingDescription}</span>
			</div>
			<div className={styles.settingsCardContentContainer}>{children}</div>
		</div>
	);
};

export const SystemAdministration = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);

	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors }
	} = useForm({
		defaultValues: async () => {
			const res = await API.getAppSettings();
			if (!res.data) toast.error(t('admin.errorLoadingSettings'));

			return res.data;
		}
	});

	const saveAppSettings = async () => {
		setIsLoading(true);
		const appSettings = getValues();

		API.saveAppSettings(appSettings as any)
			.then(res => {
				if (res.code === 200) {
					toast.success(t('admin.saved'));
				}
			})
			.catch(error => {
				toast.error(t('admin.errorSaving'));
			})
			.finally(() => {
				setIsLoading(false);
				setIsConfirmDialogOpen(false);
			});
	};

	const handleConfirmDialogClose = () => {
		setIsConfirmDialogOpen(false);
	};

	const isVersionFieldValid = (version: string): boolean => {
		let isValid = true;
		if (semver.valid(version) == null) isValid = false;

		return isValid;
	};

	const onSubmit = (data: any) => {
		setIsConfirmDialogOpen(true);
	};

	const onInvalid = () => {
		toast.error('Error saving..');
	};

	return (
		<SimpleLayout passedStyles={styles.root}>
			<form onSubmit={handleSubmit(onSubmit, onInvalid)}>
				<Paper elevation={0}>
					<div style={{ padding: '16px', display: 'flex' }}>
						<Typography variant="h1">{t('systemAdministrationPage.title')}</Typography>
					</div>
					<SettingCard
						title={t('systemAdministrationPage.appVersionControl')}
						settingDescription={
							<div>
								{t('systemAdministrationPage.appVersionControlText1')}
								<br />
								<br />
								{t('systemAdministrationPage.appVersionControlText2')}
							</div>
						}>
						<FormLabel>{t('systemAdministrationPage.androidAppVersion')}</FormLabel>
						<TextField
							{...register('currentAndroidAppVersion', { required: true, validate: isVersionFieldValid })}
							error={errors.currentAndroidAppVersion ? true : false}
							helperText={errors.currentAndroidAppVersion ? 'Invalid' : ''}
						/>
						<div style={{ paddingTop: '16px' }} />
						<FormLabel>{t('systemAdministrationPage.supportedAndroidVersion')}</FormLabel>
						<TextField
							{...register('supportedAndroidAppVersion', { required: true, validate: isVersionFieldValid })}
							error={errors.supportedAndroidAppVersion ? true : false}
							helperText={errors.supportedAndroidAppVersion ? 'Invalid' : ''}
						/>
						<div className={styles.appSettingsHr} />
						<FormLabel>{t('systemAdministrationPage.iOSAppVersion')}</FormLabel>
						<TextField
							{...register('currentiOSAppVersion', { required: true, validate: isVersionFieldValid })}
							error={errors.currentiOSAppVersion ? true : false}
							helperText={errors.currentiOSAppVersion ? 'Invalid' : ''}
						/>
						<div style={{ paddingTop: '16px' }} />
						<FormLabel>{t('systemAdministrationPage.supportediOSVersion')}</FormLabel>
						<TextField
							{...register('supportediOSAppVersion', { required: true, validate: isVersionFieldValid })}
							error={errors.supportediOSAppVersion ? true : false}
							helperText={errors.supportediOSAppVersion ? 'Invalid' : ''}
						/>
					</SettingCard>
				</Paper>
				<div style={{ paddingTop: '16px' }}>
					<Button type="submit" variant="contained">
						{t('systemAdministrationPage.save')}
					</Button>
				</div>
			</form>

			<Dialog open={isConfirmDialogOpen} onClose={handleConfirmDialogClose}>
				<DialogContent>
					<DialogContentText>{t('systemAdministrationPage.confirmDialogText')}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleConfirmDialogClose}>{t('systemAdministrationPage.cancel')}</Button>
					<LoadingButton loading={isLoading} variant="contained" onClick={saveAppSettings}>
						{t('systemAdministrationPage.save')}
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</SimpleLayout>
	);
};
