import React, { useEffect, useState } from 'react';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import { API } from '../../../api';
import { Box, CircularProgress, LinearProgress, Table, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ProjectsData } from '../../../models/projects';
import { useTranslation } from 'react-i18next';



export const Dashboard = () => {
	const [projects, setProjects] = useState<ProjectsData[]>([]);
	const [totalItems, setTotalItems] = useState<number>(0);
	const [rows, setRows] = useState<any[]>([]);
	const {t} = useTranslation ()

	const [paginationModel, setPaginationModel] = useState<any>({
		pageSize: 15,
		page: 0
	});

	const columns: GridColDef[] = [
		{ field: 'id', headerName: t("dashboard.projectId"), width: 120 },
		{ field: 'projectName', headerName: t("dashboard.projectName"), width: 250 },
		{
			field: 'missionProgress',
			headerName: t("dashboard.missionProgress"),
			width: 350,
			renderCell: params => {
				return (
					<Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', top: '10px' }}>
						<Box sx={{ width: '100%', mr: 1 }}>
							<LinearProgress
								sx={{
									padding: '5px',
									height: '25px',
									borderRadius: '4px',
									backgroundColor: '#FDE568',
									'& .MuiLinearProgress-bar': {
										backgroundColor: '#139074'
									}
								}}
								variant="determinate"
								value={params.row.missionProgress || 0}
							/>
						</Box>
						<Box sx={{ minWidth: 35 }}>
							<Typography variant="body2" color="text.secondary">{`${params.row.missionProgress || 0}%`}</Typography>
						</Box>
					</Box>
				);
			}
		},
	
		{ field: 'remainingRewards', headerName: t("dashboard.remainingRewards"), width: 180 }
	];

	useEffect(() => {
		API.getProjectList({ limit: paginationModel.pageSize, page: paginationModel.page + 1, status: 'active' }).then(res => {
			if (res.code === 200) {
				setProjects(res.data.projects);
				setTotalItems(res.data.totalItems);
			}
		});
	}, []);

	useEffect(() => {
		API.getProjectList({ limit: paginationModel.pageSize, page: paginationModel.page + 1, status: 'active' }).then(res => {
			if (res.code === 200) {
				setProjects(res.data.projects);
				setTotalItems(res.data.totalItems);
			}
		});
	}, [paginationModel]);

	const onPaginationModelChange = (newModel: any) => {
		setPaginationModel(newModel);
	};

	useEffect(() => {
		const newRows = projects.map(project => {
			return {
				id: project.id,
				projectName: project.name,
				missionProgress: project.missionProgress,
				remainingRewards: project.remainingRewards
			};
		});
		setRows(newRows);
	}, [projects]);

	return (
		<SimpleLayout passedStyles="">
			<Typography variant="h4">{t("dashboard.title")}</Typography>
				<DataGrid
					rows={projects}
					columns={columns}
					pageSize={paginationModel.pageSize}
					paginationMode="server"
					rowCount={totalItems}
					pagination={true}
					onPageChange={(page) => onPaginationModelChange({ ...paginationModel, page })}
					rowsPerPageOptions={[10, 20, 50]}
					localeText={{
					  noRowsLabel: t('no rows'),
					}}
				/>
		</SimpleLayout>
	);
};
