// @ts-ignore
import mapboxgl, { Map, Marker, Popup } from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FeatureCollection } from 'geojson';
import MapIcon from '@mui/icons-material/Map';
import CircularProgress from '@mui/material/CircularProgress';

import styles from './projectMap.module.scss';

import { VideoItemDialog } from './VideoItemDialog';
import authStore from '../../../stores/authStore';
import { getCustomProjectStyle, getPhotoProjectStyle, getVideoProjectStyle, getInvasiveProjectStyle } from '../../../api/mapApi';
import { ProjectsData } from '../../../models/projects';
import { API } from '../../../api';
import { toast } from 'react-toastify';
import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material';
import { MissionType } from '../videos/Uploads';
import { t } from 'i18next';
import { InvasiveSpeciesDialog } from './InvasiveSpeciesDialog';
import { PhotoItemDialog } from './PhotoItemDialog';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string;
const initialLocationParams = new URLSearchParams(window.location.search);

interface Project {
	name: string;
	id: number;
	projectTypeId: number;
	lat: string;
	lon: string;
}

// I didn't think at all how to update the map when a upload is changed.
// Maybe for now I should support url stuff and then leave it at that
export function ProjectMap() {
	const mapContainer = useRef<HTMLDivElement>(null);
	const [map, setMap] = useState<Map | null>(null);
	const [projects, setProjects] = useState<ProjectsData[]>([]);
	const [isProjectListLoading, setIsProjectListLoading] = useState<boolean>(false);
	const [selectedProject, setSelectedProject] = useState<Project | null>(null);
	const history = useHistory();
	//const [projectStatus, setProjectStatus] = useState<string>('all');

	// Sets the initial paramaters of the map based on the URL, or sets it to Tampere.
	// LocationAPI might be good.
	const initialLng: number = Number(initialLocationParams.get('lng')) || 23.76;
	const initialLat: number = Number(initialLocationParams.get('lat')) || 61.49;
	const initialZoom: number = Number(initialLocationParams.get('zoom')) || 15;
	const initialProjectId: number | undefined = Number(initialLocationParams.get('projectId')) || undefined;

	// Used to show a loading indicator when the map is loading
	const [isLoading, setIsLoading] = useState<boolean>(false);

	// Used to determine which style of map we use
	const [isSatelliteStyle, setIsSatelliteStyle] = useState<boolean>(false);

	// Photo marker id's are using sessionids
	// Video waypoints are using session upload ids
	// We need to store the id of the clicked item and the type so that
	// the dialog knows how to handle the item
	const [clickedItem, setClickedItem] = useState<{ id: number; projectTypeId: number } | null>(null);
	const [isVideoItemDialogOpen, setIsVideoItemDialogOpen] = useState<boolean>(false);
	const [isPhotoItemDialogOpen, setIsPhotoItemDialogOpen] = useState<boolean>(false);

	const [isInvasiveSessionDialogOpen, setIsInvasiveSessionDialogOpen] = useState<boolean>(false);
	const [selectedSightingMarker, setSelectedSightingMarker] = useState<any>(null);

	const options = useMemo(() => {
		return projects.map((p: Project) => {
			return { name: p.name, id: p.id, projectTypeId: p.projectTypeId, lat: p.lat, lon: p.lon };
		});
	}, [projects]);

	const loadProjectList = async () => {
		setIsProjectListLoading(true);

		API.getProjectList({
			countryId: undefined,
			fields: 'lat,lon,name,id,projectTypeId',
			hideFinished: false,
			language: 'en'
		})
			.then((res: any) => {
				setProjects(res.data.projects);
				// If there is an project passed as url paramater, find it.
				const project = res.data.projects.find((p: any) => p.id === initialProjectId);

				if (project) {
					changeProject(project, false);
				}
			})
			.catch(error => {
				console.error(error);
				toast.error('Failed to fetch missions', { autoClose: 1500 });
			})
			.finally(() => setIsProjectListLoading(false));
	};

	const onMoveEnd = () => {
		const { lng, lat } = map.getCenter();
		const zoom = map.getZoom();

		updateUrlParamaters(lat, lng, zoom, selectedProject?.id);
	};

	const changeProject = (project: Project, jump: boolean = true) => {
		setSelectedProject(project);
	};

	const updateUrlParamaters = (lat: number, lng: number, zoom: number, projectId: number | undefined) => {
		const newParams = new URLSearchParams();
		newParams.set('lat', lat.toString());
		newParams.set('lng', lng.toString());
		newParams.set('zoom', zoom.toString());

		if (projectId) newParams.set('projectId', projectId.toString());

		history.replace({ search: newParams.toString() });
	};

	const loadProjectResponses = async () => {
		if (!map) return;
		setIsLoading(true);

		if (!selectedProject) {
			removeExistingVideoRoutes();

			if (isSatelliteStyle) map?.setStyle('mapbox://styles/mapbox/satellite-v9');
			else map?.setStyle('mapbox://styles/mapbox/streets-v12');

			setIsLoading(false);
			return;
		}

		if (selectedProject.projectTypeId == MissionType.photo) {
			getPhotoProjectStyle(selectedProject.id, isSatelliteStyle)
				.then(res => {
					const photoStyle = res.data;
					map.setStyle(photoStyle);
					toast.success(t('projectMap.projectLoadedSuccess'), { autoClose: 1500 });
				})
				.catch(err => {
					console.error(err);
					toast.error(t('projectMap.projectLoadedError'), { autoClose: 1500 });
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else if (selectedProject.projectTypeId == MissionType.video) {
			getVideoProjectStyle(selectedProject.id, isSatelliteStyle)
				.then(res => {
					const videoStyle = res.data;
					map.setStyle(videoStyle);

					renderVideoRoutes();
					toast.success(t('projectMap.projectLoadedSuccess'), { autoClose: 1500 });
				})
				.catch(err => {
					console.error(err);
					toast.error(t('projectMap.projectLoadedError'), { autoClose: 1500 });
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else if (selectedProject.projectTypeId == MissionType.custom) {
			// Custom responses need to be loaded seperately.
			getCustomProjectStyle(selectedProject.id, isSatelliteStyle)
				.then(res => {
					const customStyle = res.data;
					map.setStyle(customStyle);
					renderVideoRoutes();
					toast.success(t('projectMap.projectLoadedSuccess'), { autoClose: 1500 });
				})
				.catch(err => {
					toast.error(t('projectMap.projectLoadedError'), { autoClose: 1500 });
					console.error(err);
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else if (selectedProject.projectTypeId == MissionType.invasiveSpecies) {
			getInvasiveProjectStyle(selectedProject.id)
				.then(res => {
					const invasiveStyle = res.data;
					map.setStyle(invasiveStyle);
					toast.success(t('projectMap.projectLoadedSuccess'), { autoClose: 1500 });
				})
				.catch(err => {
					toast.error(t('projectMap.projectLoadedError'), { autoClose: 1500 });
					console.error(err);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	const removeExistingVideoRoutes = () => {
		if (!map) return;

		if (map.getLayer('videoRoute')) map.removeLayer('videoRoute');
		if (map.getSource('videoRoute')) map.removeSource('videoRoute');
	};

	const renderVideoRoutes = async () => {
		if (!map) return;
		if (!selectedProject) return;

		const videoWaypointsRes = await API.getProjectWaypoints(selectedProject.id);
		const videos = videoWaypointsRes.data.videos;

		if (!videos) return;

		const sourceData: FeatureCollection = {
			type: 'FeatureCollection',
			features: videos.map((v: any) => {
				return {
					type: 'Feature',
					properties: {
						id: v.id,
						status: v.status
					},
					geometry: {
						type: 'LineString',
						coordinates: v.coordinates
					}
				};
			})
		};

		map.addSource('videoRoute', {
			type: 'geojson',
			data: sourceData
		});

		map.addLayer({
			id: 'videoRoute',
			type: 'line',
			source: 'videoRoute',
			layout: {
				'line-join': 'round',
				'line-cap': 'round'
			},
			paint: {
				'line-width': 4,
				'line-color': [
					'match',
					['get', 'status'],
					'approved',
					'#0A775F',
					'pending',
					'#F0CD14',
					'rejected',
					'#FE5F55',
					'#a3a3a3' // fallback color
				]
			}
		});
	};

	const onVideoRouteClick = (e: any) => {
		if (!selectedProject) return;

		if (e.features && e.features.length > 0 && e.features[0].properties) {
			setClickedItem({ id: e.features[0].properties.id, projectTypeId: MissionType.video });
			setIsVideoItemDialogOpen(true);
		}
	};

	const onPhotoMarkerClick = (e: any) => {
		setClickedItem({ id: e.features[0].id, projectTypeId: MissionType.photo });
		console.log({ id: e.features[0].id, projectTypeId: MissionType.photo });
		setIsPhotoItemDialogOpen(true);
	};

	const onSightingMarkerClick = (e: any) => {
		setSelectedSightingMarker(e.features[0].properties);
		setIsInvasiveSessionDialogOpen(true);
	};

	useEffect(() => {
		if (!map) return;
		// Update the URL to include the new selected project id.
		selectedProject && map.setCenter([Number(selectedProject?.lon), Number(selectedProject?.lat)]);
		updateUrlParamaters(map.getCenter().lat, map.getCenter().lng, map.getZoom(), selectedProject?.id);
		loadProjectResponses();

		// Attach event handlers
		map.on('moveend', onMoveEnd);
		map.on('click', 'videoRoute', onVideoRouteClick);
		map.on('click', 'photomarker', onPhotoMarkerClick);
		map.on('click', 'sightingmarker', onSightingMarkerClick);

		// Remove event handlers
		return () => {
			map.off('moveend', onMoveEnd);
			map.off('click', onVideoRouteClick);
			map.off('click', onPhotoMarkerClick);
			map.off('click', onSightingMarkerClick);
		};
	}, [selectedProject, map]);

	useEffect(() => {
		loadProjectResponses();
	}, [isSatelliteStyle]);

	useEffect(() => {
		loadProjectList();

		/*
		Maybe geolocation api?
		const handleSuccess = (position: GeolocationPosition) => {
			console.log(position);
		};
		const handleError = () => {};

		navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
		*/
		const initialMap = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/mapbox/streets-v12',
			transformRequest: (url: string, resourceType: string) => {
				const gisApiUrl = process.env.REACT_APP_GIS_API || '';
				// We need to add the auth header to calls to the map server
				// but not to mapbox itself, otherwise errors.
				if (url.includes(gisApiUrl)) {
					return {
						url,
						headers: {
							authorization: `Bearer ${authStore.token}`,
							// @ts-ignore
							'X-Api-Key': process.env.REACT_APP_API_KEY
						}
					};
				}
			},
			center: [initialLng, initialLat],
			zoom: initialZoom
		});

		initialMap.on('mouseenter', 'videoRoute', () => (initialMap.getCanvas().style.cursor = 'pointer'));
		initialMap.on('mouseleave', 'videoRoute', () => (initialMap.getCanvas().style.cursor = ''));

		initialMap.on('load', () => {
			setMap(initialMap);
		});
	}, []);

	return (
		<div>
			<div className={styles.searchbar}>
			<Autocomplete
			loading={isProjectListLoading}
			onChange={(event, project) => changeProject(project as any, true)}
			value={selectedProject}
			renderOption={(props, option) => {
				return (
				<li {...props} key={option.id} value={option.id}>
					<div style={{ whiteSpace: 'normal', wordWrap: 'break-word', wordBreak: 'break-word' }} data-value={option.id}>
					{option.name}{' '}
					<sup data-value={option.id} style={{ color: 'grey' }}>
						({`ID: ${option.id}`})
					</sup>
					</div>
				</li>
				);
			}}
			getOptionLabel={option => option.name}
			options={options}
			renderInput={params => <TextField placeholder={t('Select mission...')} sx={{ display: 'flex', alignContent: 'center' }} {...params} />}
			sx={{ display: 'flex', alignContent: 'center', width: '400px' }}
			noOptionsText={t('No options')}
			/>
				<Tooltip title={t('Fetch Satellite')} placement="bottom">
					<IconButton color={isSatelliteStyle ? 'primary' : 'default'} onClick={() => setIsSatelliteStyle(!isSatelliteStyle)}>
						<MapIcon />
					</IconButton>
				</Tooltip>
			</div>

			{isLoading ? (
				<div className={styles.overlay}>
					<CircularProgress />
				</div>
			) : null}

			<div ref={mapContainer} className={styles.map} style={{ width: '100%', height: '100vh' }} />
			{isPhotoItemDialogOpen && clickedItem && (
				<PhotoItemDialog isOpen={isPhotoItemDialogOpen} onClose={() => setIsPhotoItemDialogOpen(false)} sessionId={clickedItem.id} />
			)}

			{isVideoItemDialogOpen && clickedItem && (
				<VideoItemDialog isOpen={isVideoItemDialogOpen} onClose={() => setIsVideoItemDialogOpen(false)} sessionUploadId={clickedItem.id} />
			)}
			{isInvasiveSessionDialogOpen && (
				<InvasiveSpeciesDialog
					open={isInvasiveSessionDialogOpen}
					onClose={() => setIsInvasiveSessionDialogOpen(false)}
					area={selectedSightingMarker.area}
					markerId={selectedSightingMarker.marker}
					initSession={selectedSightingMarker.init_session}
					sightingId={selectedSightingMarker.id}
				/>
			)}
		</div>
	);
}

