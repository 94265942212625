import { FormControl, Select, MenuItem, Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectsData } from '../../../models/projects';
import styles from './SelectList.module.scss';
import { MainQueryKey, QueryKey, useSelectListQuery } from '../../../queries/view-queries/useSelectListQuery';
import { Countries } from '../../../models/general';
import { PaginationMainQueryKey, usePaginationQueryClient } from '../../../queries/view-queries/usePaginationQuery';
import { t } from 'i18next';

interface FileStatusesOptionI {
	id: string;
	name: string;
}

interface ObjectStatusesOptionI {
	id: number;
	name: string;
}

interface PropsI {
	inputWidth?: string;
	mainQueryKey: MainQueryKey;
	queryKey: QueryKey;
	data?: ProjectsData[] | Countries[] | FileStatusesOptionI[] | ObjectStatusesOptionI[];
	labelId: string;
	id: string;
	htmlFor: string;
	labelText: string;
	sx?: Record<string, string>;
}

type MapType = ProjectsData | Countries | FileStatusesOptionI | ObjectStatusesOptionI;

export const FileStatusesOption = [
	{ id: 'review', name: 'Review' },
	{ id: 'approved', name: 'Approved' },
	{ id: 'errored', name: 'Errored' },
	{ id: 'inVaisala', name: 'In Vaisala' },
	{ id: 'rejected', name: 'Rejected' },
	{ id: 'rejectedWithPayment', name: 'Rejected With Payment' },
	{ id: 'notUploaded', name: 'Not uploaded' }
];

export const ObjectStatusOptions = [
	{
		id: 1,
		name: 'Approved'
	},
	{
		id: 2,
		name: 'Pending'
	},
	{
		id: 3,
		name: 'Rejected'
	}
];

const SelectList = (props: PropsI): JSX.Element => {
	const { data, labelId, id, htmlFor, labelText, queryKey, mainQueryKey, sx } = props;
	const { t } = useTranslation();

	/////////////////////////////////////////////// REACT-QUERY ////////////////////////////////////////////////////////
	const { setSelectedOption, getSelectedOption } = useSelectListQuery(mainQueryKey, queryKey);
	const { setPaginationData } = usePaginationQueryClient(1);
	const selected = getSelectedOption(mainQueryKey, queryKey) || 'all';
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	return (
		<FormControl className={styles.formControl}>
			<Box component="label" htmlFor={htmlFor}>
				{t(labelText || '')}
			</Box>
			<Select
				sx={sx ? sx : { width: props.inputWidth }}
				labelId={labelId}
				id={id}
				value={selected}
				onChange={e => (setSelectedOption(mainQueryKey, queryKey, e.target.value), setPaginationData(PaginationMainQueryKey.uploads, 1))}
				displayEmpty>
				<MenuItem value="all">
					{t('all')}
				</MenuItem>
				{data?.map((item: MapType, index: number) => {
					return (
						<MenuItem key={index} value={item.id}>
							{item.name}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

export default SelectList;
