import { Box, Paper, ToggleButton, TextField, Button } from '@mui/material';
import { Step } from '../../../stores/missionEditorStore';
import { EditorContent, useEditor } from '@tiptap/react';
import { observer } from 'mobx-react';
import missionEditorStore from '../../../stores/missionEditorStore';
import { useCallback } from 'react';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import AddLinkIcon from '@mui/icons-material/AddLink';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Document from '@tiptap/extension-document';
import Dropcursor from '@tiptap/extension-dropcursor';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useDropzone } from 'react-dropzone';
import ImageIcon from '@mui/icons-material/Image';
import { t } from 'i18next';
function EditorMenu({ editor }: any) {
	const setLink = useCallback(() => {
		const previousUrl = editor.getAttributes('link').href;
		const url = window.prompt('URL', previousUrl);

		// cancelled
		if (url === null) {
			return;
		}

		// empty
		if (url === '') {
			editor.chain().focus().extendMarkRange('link').unsetLink().run();
			return;
		}

		// update link
		editor.chain().focus().extendMarkRange('link').setLink({ href: url, target: '_blank' }).run();
	}, [editor]);

	if (!editor) {
		return null;
	}

	return (
		<Box style={{ display: 'flex', gap: '5px', borderBottom: '1px solid rgba(0, 0, 0, 0.1)', paddingBottom: '12px' }}>
			<ToggleButton selected={editor.isActive('bold')} size="small" value="bold">
				<FormatBoldIcon fontSize="small" onClick={() => editor.chain().focus().toggleBold().run()} />
			</ToggleButton>
			<ToggleButton selected={editor.isActive('italic')} size="small" value="italic">
				<FormatItalicIcon fontSize="small" onClick={() => editor.chain().focus().toggleItalic().run()} />
			</ToggleButton>
			<ToggleButton selected={editor.isActive('link')} size="small" value="link">
				<AddLinkIcon fontSize="small" onClick={setLink} />
			</ToggleButton>
			<ToggleButton selected={editor.isActive('bulletList')} size="small" value="bulletList">
				<FormatListBulletedIcon fontSize="small" onClick={() => editor.chain().focus().toggleBulletList().run()} />
			</ToggleButton>
		</Box>
	);
}

function LobbyDropzone(props: { references: any }) {
	const { setReferences } = missionEditorStore;
	const { references } = props;

	const onDrop = (file: any) => {
		setReferences([...references, { file, caption: '' }]);
	};

	const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: true, accept: '.jpg, .png, .jpeg' });

	return (
		<div>
			<label>{t('missionEditor.lobbyStep.referenceImages')}</label>
			<div
				{...getRootProps()}
				style={{
					background: '#F9F8F2',
					border: '1px dashed #A4A4A4',
					boxSizing: 'border-box',
					borderRadius: '8px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					padding: '24px',
					cursor: 'pointer'
				}}>
				<input {...getInputProps()} />
				<ImageIcon color="primary" fontSize="large" />
				<span>{t('missionEditor.lobbyStep.clickToUploadOr')}</span>
			</div>
		</div>
	);
}

export const LobbyStep = observer((props: { step: Step }) => {
	const { step } = props;
	const stepIndex = step.order - 1;
	const { setStepProperty, errors, mission } = missionEditorStore;
	const contentError = errors[`[${stepIndex}].lobbyParams.content`];

	const editor = useEditor(
		{
			extensions: [StarterKit, Link, Image, Document, Dropcursor],
			onUpdate: () => setStepProperty(step, 'content', editor?.getHTML()),
			content: step.lobbyParams?.content
		},
		[step]
	);

	const handleRemoveOldReference = (index: number) => {
		mission.oldReferences.splice(index, 1);
	};

	const handleRemoveReferece = (index: number) => {
		mission.references.splice(index, 1);
	};

	return (
		<Box sx={{ paddingTop: '20px', display: 'flex', gap: '20px' }}>
			<div style={{ width: '100%' }}>
				<div style={{ display: 'flex', gap: '20px', flexDirection: 'column', paddingBottom: '20px' }}>
					<LobbyDropzone references={mission.references} />
					{mission.oldReferences.map((reference: any, index) => {
						return (
							<Box sx={{ display: 'flex' }}>
								<img src={reference.URL} style={{ maxHeight: '216px', maxWidth: '172px' }} />
								<Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '12px', width: '100%' }}>
									<span>{reference.caption}</span>
									<TextField
										onChange={e => {
											reference.caption = e.target.value;
										}}
										value={reference.caption}
									/>
									<Button
										onClick={() => handleRemoveOldReference(index)}
										startIcon={<DeleteForeverIcon />}
										sx={{ color: '#FE5F55', width: '150px', paddingLeft: 0, justifyContent: 'flex-start' }}
										variant="text">
										Remove photo
									</Button>
								</Box>
							</Box>
						);
					})}
					{mission.references.map((reference: any, index) => {
						return (
							<Box sx={{ display: 'flex' }}>
								<img src={URL.createObjectURL(reference.file[0])} style={{ maxHeight: '216px', maxWidth: '172px' }} />
								<Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '12px', width: '100%' }}>
									<span>{reference.file[0].name}</span>
									<TextField
										onChange={e => {
											reference.caption = e.target.value;
										}}
									/>
									<Button
										onClick={() => handleRemoveReferece(index)}
										startIcon={<DeleteForeverIcon />}
										sx={{ color: '#FE5F55', width: '150px', paddingLeft: 0, justifyContent: 'flex-start' }}
										variant="text">
										Remove photo
									</Button>
								</Box>
							</Box>
						);
					})}
				</div>

				<Paper
					elevation={contentError ? 0 : 1}
					sx={{ padding: '12px', marginBottom: '12px', minHeight: '400px', border: contentError && '1px solid #FE5F55' }}>
					<EditorMenu editor={editor} />
					<EditorContent editor={editor} />
				</Paper>
				<p style={{ fontSize: '12px', color: '#FE5F55', paddingLeft: '15px' }}>{contentError}</p>
			</div>
		</Box>
	);
});
