import { Box, Tabs, Tab, ToggleButton, Button } from '@mui/material';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import projectEditorStore, { AvailableLanguages, Project, ProjectCardType, projectCardTypeNames } from '../../../stores/projectEditorStore';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { EditorContent, useEditor } from '@tiptap/react';

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import AddLinkIcon from '@mui/icons-material/AddLink';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import Dropcursor from '@tiptap/extension-dropcursor';
import StarterKit from '@tiptap/starter-kit';
import Document from '@tiptap/extension-document';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import { t } from 'i18next';

const CardEditDetails = (props: { cardType: ProjectCardType; language: AvailableLanguages }) => {
	const { cardType, language } = props;
	const { deleteLocalisation } = projectEditorStore;
	return (
		<Box sx={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
			<TextEditor cardType={cardType} language={language} />
			<Box>
				<Button color="error" variant="outlined" disabled={language == AvailableLanguages.en} onClick={() => deleteLocalisation(language)}>
					{t('projectEditor.deleteLocalisation')}
				</Button>
			</Box>
		</Box>
	);
};

const EditorMenu = ({ editor }: any) => {
	const setLink = useCallback(() => {
		const previousUrl = editor.getAttributes('link').href;
		const url = window.prompt('URL', previousUrl);

		// cancelled
		if (url === null) {
			return;
		}

		// empty
		if (url === '') {
			editor.chain().focus().extendMarkRange('link').unsetLink().run();
			return;
		}

		// update link
		editor.chain().focus().extendMarkRange('link').setLink({ href: url, target: '_blank' }).run();
	}, [editor]);

	if (!editor) {
		return null;
	}

	return (
		<Box style={{ display: 'flex', gap: '5px', borderBottom: '1px solid rgba(0, 0, 0, 0.1)', paddingBottom: '12px' }}>
			<ToggleButton selected={editor.isActive('bold')} size="small" value="bold">
				<FormatBoldIcon fontSize="small" onClick={() => editor.chain().focus().toggleBold().run()} />
			</ToggleButton>
			<ToggleButton selected={editor.isActive('italic')} size="small" value="italic">
				<FormatItalicIcon fontSize="small" onClick={() => editor.chain().focus().toggleItalic().run()} />
			</ToggleButton>
			<ToggleButton selected={editor.isActive('link')} size="small" value="link">
				<AddLinkIcon fontSize="small" onClick={setLink} />
			</ToggleButton>
			<ToggleButton selected={editor.isActive('bulletList')} size="small" value="bulletList">
				<FormatListBulletedIcon fontSize="small" onClick={() => editor.chain().focus().toggleBulletList().run()} />
			</ToggleButton>
		</Box>
	);
};

const TextEditor = observer((props: { language: AvailableLanguages; cardType: ProjectCardType }) => {
	const { project, errors } = projectEditorStore;
	const { language, cardType } = props;

	const onUpdate = (content: any) => {
		if (content == '<p></p>') content = '';

		project.cards.find(card => card.type == cardType)!.content[language] = content;
	};

	useEffect(() => {
		const newContent = project.cards.find(card => card.type == cardType)?.content[language];
		// @ts-ignore
		editor?.commands.setContent(newContent);
	}, [language, cardType, project]);

	const initialContent = project.cards.find(card => card.type == cardType)?.content[language];

	const editor = useEditor({
		extensions: [StarterKit, Link, Image, Document, Dropcursor],
		onUpdate: () => onUpdate(editor?.getHTML()),
		content: initialContent
	});

	const isError = errors[`cards[${cardType - 1}].content.${language}`];
	const errorStyle = isError ? { border: '1px solid #FE5F55', borderRadius: '8px' } : {};

	return (
		<div>
			<EditorMenu editor={editor} />
			<EditorContent key={language} editor={editor} style={errorStyle} />
			{isError && (
				<span style={{ color: '#FE5F55', fontStyle: 'italic', fontSize: '0.75rem' }}>{errors[`cards[${cardType - 1}].content.${language}`]}</span>
			)}
		</div>
	);
});

export const ProjectCardsEditor = observer((props: { language: AvailableLanguages }) => {
	const { language } = props;
	const { errors } = projectEditorStore;

	// Tab stores the tab that is currently selected
	const [tab, setTab] = useState<number>(ProjectCardType.OBJECTIVE);

	const handleTabChange = (event: React.SyntheticEvent, value: number) => {
		setTab(value);
	};

	const tabHasError = (card: ProjectCardType) => {
		if (errors[`cards[${card - 1}].content.${language}`]) return true;

		return false;
	};

	return (
		<Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%', gap: '10px' }}>
			<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
				<Tabs value={tab} onChange={handleTabChange}>
					<Tab
						value={ProjectCardType.OBJECTIVE}
						icon={
							<span>
								{t('Objective')}
								{tabHasError(ProjectCardType.OBJECTIVE) && <ErrorOutlineIcon color="error" fontSize="small" />}
							</span>
						}
					/>
					<Tab
						value={ProjectCardType.METHOD}
						icon={
							<span>
								{t('Method')}
								{tabHasError(ProjectCardType.METHOD) && <ErrorOutlineIcon color="error" fontSize="small" />}
							</span>
						}
					/>
					<Tab
						value={ProjectCardType.EQUIPMENT}
						icon={
							<span>
								{t('Equipment')}
								{tabHasError(ProjectCardType.EQUIPMENT) && <ErrorOutlineIcon color="error" fontSize="small" />}
							</span>
						}
					/>
					<Tab
						value={ProjectCardType.CONDITIONS}
						icon={
							<span>
								{t('Conditions')}
								{tabHasError(ProjectCardType.CONDITIONS) && <ErrorOutlineIcon color="error" fontSize="small" />}
							</span>
						}
					/>
					<Tab
						value={ProjectCardType.SAFETY}
						icon={
							<span>
								{t('Safety')}
								{tabHasError(ProjectCardType.SAFETY) && <ErrorOutlineIcon color="error" fontSize="small" />}
							</span>
						}
					/>
				</Tabs>
			</Box>
			{tab == ProjectCardType.OBJECTIVE && <CardEditDetails language={language} cardType={ProjectCardType.OBJECTIVE} />}
			{tab == ProjectCardType.METHOD && <CardEditDetails language={language} cardType={ProjectCardType.METHOD} />}
			{tab == ProjectCardType.EQUIPMENT && <CardEditDetails language={language} cardType={ProjectCardType.EQUIPMENT} />}
			{tab == ProjectCardType.CONDITIONS && <CardEditDetails language={language} cardType={ProjectCardType.CONDITIONS} />}
			{tab == ProjectCardType.SAFETY && <CardEditDetails language={language} cardType={ProjectCardType.SAFETY} />}
		</Box>
	);
});
