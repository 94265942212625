import { observer } from 'mobx-react';
import React from 'react';
import { t } from 'i18next';

import { ProjectEditorSection } from './ProjectEditorComponents';
import { ProjectInvasiveObjectEditor } from './ProjectInvasiveObjectEditor';

export const ProjectInvasiveSpeciesEditor = observer(() => {
	return (
			<ProjectEditorSection title={t('invasiveSpeciesEditor.title')}>
				<ProjectInvasiveObjectEditor />
			</ProjectEditorSection>
	);
});
