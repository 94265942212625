import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Table, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { MissionType } from '../videos/Uploads';
import { API } from '../../../api';
import { UploadData } from '../../../models/video';
import authStore from '../../../stores/authStore';
import { RejectionDialog } from './RejectionDialog';
import { getObjectName } from '../../../utils';
import { SessionStatusChip } from '../videos/InvasiveSpeciesItem';
import { RejectWithPaymentDialog } from './RejectWithPaymentDialog';
import { ApproveDialog } from './ApproveDialog';
import { t } from 'i18next';

const { adminRole } = authStore;

interface ProjectMapItemDialogProps {
	isOpen: boolean;
	onClose: () => void;
	sessionId: number;
}

export const PhotoItemDialog = (props: ProjectMapItemDialogProps) => {
	const { isOpen, onClose, sessionId } = props;

	const [item, setItem] = useState<UploadData>();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [isApproveDialogOpen, setIsApproveDialogOpen] = useState<boolean>(false);
	const [isRejectDialogOpen, setIsRejectDialogOpen] = useState<boolean>(false);
	const [isRejectWithPaymentDialogOpen, setIsRejectWithPaymentDialogOpen] = useState<boolean>(false);

	const uploadId = item?.projectTypeId === MissionType.photo ? item?.photos[0]?.sessionUploadId : item?.id;

	const PhotoDialogContent = useMemo(() => {
		const TableHeader = (props: { title: string }) => {
			const { title } = props;
			return (
				<TableCell>
					<Typography variant="caption" color="primary" fontWeight={'900'} letterSpacing={'0.05735em'}>
						{title}
					</Typography>
				</TableCell>
			);
		};

		return (
			<Box display="flex" gap="20px" flexDirection={'column'}>
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					gap={2} // spacing between photos
					flexDirection="row"
					width="100%">
					{item?.photos && item?.photos.length > 0 ? (
						item.photos.slice(0, 4).map((photo, index) => (
							<Box
								key={index}
								component="img"
								onMouseDown={event => {
									if (event.button === 1 || event.button === 0) {
										event.preventDefault();
										window.open(photo.url, '_blank');
									}
								}}
								src={photo.url}
								alt={`Photo ${index}`}
								sx={{
									width: '100%',
									height: 'auto',
									maxHeight: '500px',
									objectFit: 'cover',
									borderRadius: '8px',
									boxShadow: 3,
									cursor: 'pointer'
								}}
							/>
						))
					) : (
						<Typography variant="h4" color="primary">
							No photos found
						</Typography>
					)}
				</Box>

				<Table>
					<TableHead>
						<TableRow>
							<TableHeader title={t('photoItemDialog.sessionUploadId')} />
							<TableHeader title={t('photoItemDialog.objectTypeId')} />
							<TableHeader title={t('photoItemDialog.objectName')} />
							<TableHeader title={t('photoItemDialog.sessionDate')} />
							<TableHeader title={t('photoItemDialog.userId')} />
							<TableHeader title={t('photoItemDialog.status')} />
						</TableRow>
					</TableHead>
					<TableRow>
						<TableCell>
							<a
								style={{ textDecoration: 'none', padding: 0 }}
								href={`${process.env.PUBLIC_URL}/image/${item?.photos[0]?.sessionUploadId}`}
								target="_blank"
								rel="noopener noreferrer">
								<Button
									sx={{
										textDecoration: 'underline',
										padding: 0
									}}
									variant="text">
									{item?.photos[0]?.sessionUploadId}
								</Button>
							</a>
						</TableCell>
						<TableCell>{item?.photos[0]?.objectTypeId}</TableCell>
						<TableCell>{getObjectName(item?.photos[0]?.objectTypeId)}</TableCell>
						<TableCell>{`${new Date(item?.startedAt).toLocaleDateString()} ${new Date(item?.startedAt).toLocaleTimeString()}`}</TableCell>
						<TableCell>
							{authStore.adminRole === 'superadmin' || authStore.adminRole === 'admin' ? (
								<a
									style={{ textDecoration: 'none', padding: 0 }}
									href={`${process.env.PUBLIC_URL}/profile/${item?.userId}`}
									target="_blank"
									rel="noopener noreferrer">
									<Button
										sx={{
											textDecoration: 'underline',
											padding: 0
										}}
										variant="text">
										{item?.userId}
									</Button>
								</a>
							) : (
								<span>{item?.userId}</span>
							)}
						</TableCell>
						<TableCell>
							<SessionStatusChip sessionStatus={item?.photos[0]?.status || ''} />
						</TableCell>
					</TableRow>
				</Table>
			</Box>
		);
	}, [sessionId, isLoading]);

	useEffect(() => {
		setIsLoading(true);
		API.getPhotoSessionData(sessionId)
			.then(res => {
				setItem(res.data);
			})
			.catch((err: any) => {
				console.error(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [sessionId]);

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			fullWidth
			maxWidth="xl"
			PaperProps={{
				style: {
					height: 'auto',
					overflowY: 'auto'
				}
			}}>
			<DialogContent>
				<div style={{ maxHeight: '762px' }}>{PhotoDialogContent}</div>
			</DialogContent>
			{adminRole === 'superadmin' || adminRole === 'admin' ? (
				<DialogActions>
					<Button onClick={() => setIsApproveDialogOpen(true)}>{t('Approve')}</Button>
					<Button onClick={() => setIsRejectWithPaymentDialogOpen(true)}>{t('rejectWithPayment')}</Button>
					<Button onClick={() => setIsRejectDialogOpen(true)}>{t('Reject')}</Button>
					<Button onClick={onClose}>{t('Close')}</Button>
				</DialogActions>
			) : (
				<></>
			)}
			<RejectionDialog isOpen={isRejectDialogOpen} onClose={() => setIsRejectDialogOpen(false)} uploadId={uploadId} onItemDialogClose={() => {}} />
			<RejectWithPaymentDialog
				isOpen={isRejectWithPaymentDialogOpen}
				onClose={() => setIsRejectWithPaymentDialogOpen(false)}
				uploadId={uploadId}
				onItemDialogClose={() => {}}
			/>
			<ApproveDialog isOpen={isApproveDialogOpen} onClose={() => setIsApproveDialogOpen(false)} uploadId={uploadId} onItemDialogClose={() => {}} />
		</Dialog>
	);
};
