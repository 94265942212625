import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import CarchupaPaper from '../../../components/UI/papper/CarchupaPaper';
import MaterialTable from 'material-table';
import * as Yup from 'yup';
import { API } from '../../../api';
import { Header } from '../../../models/tables';
import messageStore from '../../../stores/messageStore';
import settingsStore from '../../../stores/settingsStore';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

import styles from './Superadmin.module.scss';
import adminStore from '../../../stores/adminStore';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useCallbackWithLoading } from '../../../hooks/use-callback-with-loading';

interface SubmitValues {
	firstName: string;
	lastName: string;
	email: string;
	username: string;
}

const Superadmin = () => {
	const { t } = useTranslation();
	const { selectedRowCount } = settingsStore;
	const { admins, selectedAdminTablePage } = adminStore;
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
	const [isSetAsSuperAdminDialogOpen, setIsSetAsSuperAdminDialogOpen] = useState<boolean>(false);
	const [selectedAdmins, setSelectedAdmins] = useState<number[]>([]);
	const [loading, setIsLoading] = useState(true);
	const [add, sendPending] = useCallbackWithLoading(API.addAdmin);

	const initialValues = {
		firstName: '',
		lastName: '',
		username: '',
		email: ''
	};

	const validate = Yup.object().shape({
		firstName: Yup.string().required('Required'),
		lastName: Yup.string().required('Required'),
		username: Yup.string().required('Required'),
		email: Yup.string().email().required('Required')
	});

	const headCells: Header[] = [
		{
			field: 'ID',
			title: 'ID',
			sortable: true
		},
		{
			field: 'firstName',
			title: t('firstName'),
			sortable: true
		},
		{
			field: 'lastName',
			title: t('lastName'),
			sortable: true
		},
		{
			field: 'username',
			title: t('username'),
			sortable: true
		},
		{
			field: 'email',
			title: t('Email'),
			sortable: true
		},
		{
			field: 'isSuper',
			title: t('Super Admin'),
			sortable: true,
			render: (rowData: any) => (rowData.isSuper ? t('Yes') : t('No'))
		},

		{
			field: 'createdAt',
			title: t('createdAt'),
			sortable: true
		}
	];

	const loadAdmins = useCallback(async () => {
		setIsLoading(true);
		const response = await API.getAdminsList();

		if (response) {
			adminStore.updateAdmins(response.data.admins);
		} else {
			messageStore.snackbar({
				message: t('Failed fetching admins'),
				type: 'error'
			});
		}

		setIsLoading(false);
	}, [t]);

	const addAdmin = useCallback(
		async (values: SubmitValues, setSubmitting: (isSubmitting: boolean) => void, resetForm) => {
			const { firstName, lastName, username, email } = values;
			await add(firstName, lastName, username, email)
				.then(res => {
					if (res.code === 2000) {
						messageStore.snackbar({
							message: `${t('Admin added')}`,
							type: 'info'
						});
					} else if (res.code === 2001) {
						messageStore.snackbar({
							message: res.message,
							type: 'info'
						});
						loadAdmins();
						resetForm();
					} else if (res.code === 1141) {
						messageStore.snackbar({
							message: res.message,
							type: 'info'
						});
						loadAdmins();
						resetForm();
					} else if (res.code === 1142) {
						messageStore.snackbar({
							message: res.message,
							type: 'info'
						});
					}
				})
				.catch(err => {
					messageStore.snackbar({
						message: err.message,
						type: 'error'
					});
				});
			setSubmitting(false);
		},
		[add, t] // + loadAdmins
	);

	const deleteAdmins = async () => {
		if (!selectedAdmins) return;
		setIsLoading(true);

		await API.deleteAdmins(selectedAdmins)
			.then(() => {
				messageStore.snackbar({ message: `Admin${selectedAdmins.length > 1 ? 's' : ''} deleted`, type: 'info' });
				setIsDeleteDialogOpen(false);
				loadAdmins();
			})
			.catch(err => {
				messageStore.snackbar({ message: err.message, type: 'error' });
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const setAsSuperAdmin = async () => {
		if (!selectedAdmins) return;
		setIsLoading(true);

		await API.setSuperAdmin(selectedAdmins)
			.then(() => {
				messageStore.snackbar({ message: `Admin${selectedAdmins.length > 1 ? 's' : ''} set as super admins`, type: 'info' });
				setIsSetAsSuperAdminDialogOpen(false);
				loadAdmins();
			})
			.catch(err => {
				messageStore.snackbar({ message: err.message, type: 'error' });
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		adminStore.updateAdmins([]);
		loadAdmins();
	}, [loadAdmins, admins]);

	return (
		<SimpleLayout passedStyles={styles.root}>
			{admins.length !== 0 ? (
				<MaterialTable
					title={t('adminList')}
					columns={headCells}
					data={admins}
					options={{
						search: true,
						selection: true,
						pageSize: selectedRowCount,
						initialPage: Math.ceil(admins.length / selectedRowCount) >= selectedAdminTablePage ? selectedAdminTablePage : 0
					}}
					actions={[
						{
							tooltip: t('Remove All Selected Users'),
							icon: 'delete',
							onClick: (evt, data: any[]) => {
								setIsDeleteDialogOpen(true);
							}
						},
						{
							tooltip: t('admin.setAsSuperAdmin'),
							icon: () => <SupervisorAccountIcon />,
							onClick: (evt, data: any[]) => {
								setIsSetAsSuperAdminDialogOpen(true);
							}
						}
					]}
					components={{
						Container: props => <CarchupaPaper {...props} type="column" parentStyle={styles.paper} childStyle={styles.table} />
					}}
					onSelectionChange={rows => {
						setSelectedAdmins(rows.map(el => el.ID));
					}}
					onChangeRowsPerPage={rows => {
						settingsStore.updateSelectedRowCount(rows);
					}}
					onChangePage={rows => {
						adminStore.updateSelectedAdminTablePage(rows);
					}}
				/>
			) : loading ? (
				<LinearProgress />
			) : (
				<CarchupaPaper type="row" parentStyle={styles.paper}>
					<div>{t('noAdminsToDisplay')}</div>
				</CarchupaPaper>
			)}

			<Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
				<Box sx={{ padding: '24px' }}>
					<DialogTitle>{t('admin.deleteAdminDialogTitle')}</DialogTitle>
					<DialogContent>{t('admin.deleteAdminDialogText')}</DialogContent>
					<DialogActions>
						<Button onClick={() => setIsDeleteDialogOpen(false)}>{t('Cancel')}</Button>
						<Button variant="contained" onClick={deleteAdmins}>
							{t('Remove')}
						</Button>
					</DialogActions>
				</Box>
			</Dialog>
			<Dialog open={isSetAsSuperAdminDialogOpen} onClose={() => setIsSetAsSuperAdminDialogOpen(false)}>
				<Box sx={{ padding: '24px' }}>
					<DialogTitle>{t('admin.setAsSuperAdminDialogTitle')}</DialogTitle>
					<DialogContent>{t('admin.setAsSuperAdminDialogText')}</DialogContent>
					<DialogActions>
						<Button onClick={() => setIsSetAsSuperAdminDialogOpen(false)}>{t('Cancel')}</Button>
						<Button variant="contained" onClick={setAsSuperAdmin}>
							{t('Save')}
						</Button>
					</DialogActions>
				</Box>
			</Dialog>

			<CarchupaPaper title={t('admin.addAdminFormTitle')} type="column" parentStyle={styles.paper}>
				<div className={styles.formContainer}>
					<Typography style={{ paddingLeft: '1%', paddingTop: '1%' }} variant="subtitle1">
						{t('admin.addAdminFormText')}
					</Typography>
					<Formik
						initialValues={initialValues}
						validateOnMount={true}
						onSubmit={(values: any, { setSubmitting, resetForm }: any) => {
							setSubmitting(true);
							addAdmin(values, setSubmitting, resetForm);
						}}
						validationSchema={validate}>
						{({ errors, touched, isSubmitting, setFieldValue }: any) => (
							<Form className={styles.adminForm}>
								<div className={styles.fieldsContainer}>
									<Field type="text" name="firstName" placeholder={t('firstName')} disabled={isSubmitting} />
									<Field type="text" name="lastName" placeholder={t('lastName')} disabled={isSubmitting} />
									<Field type="text" name="username" placeholder={t('username')} disabled={isSubmitting} />
									<Field type="email" name="email" placeholder={t('Email')} disabled={isSubmitting} />
								</div>

								{errors.title && touched.title ? <div>{errors.title}</div> : null}

								<div className={styles.submitButton}>
									<Button variant="contained" color="primary" type="submit" disabled={isSubmitting || sendPending} className={styles.submitButton}>
										{t('admin.addAdminFormButtonText')}
									</Button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</CarchupaPaper>
		</SimpleLayout>
	);
};

export default inject()(observer(Superadmin));
