import { Autocomplete, Box, Button, FormControl, InputLabel, Pagination, TextField, Typography } from '@mui/material';
import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from '../../../api';
import uploadIcon from '../../../assets/upload-icon.svg';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import VideoItem from '../../../components/UI/video-item/VideoItem';
import { DateRangePickerInput } from '../../../components/UX/date-range-picker/DateRangePickerInput';
import SelectList, { FileStatusesOption } from '../../../components/UX/select-list/SelectList';
import { BulkOptionsModal } from './BulkOptionsModal';
import { MainQueryKey, QueryKey, useSelectListQuery } from '../../../queries/view-queries/useSelectListQuery';
import { useGetProjectListQuery } from '../../../queries/data-queries/useGetProjectListQuery';
import { useGetUploadsData } from '../../../queries/data-queries/useGetUploadsData';
import { PaginationMainQueryKey, usePaginationQuery, usePaginationQueryClient } from '../../../queries/view-queries/usePaginationQuery';
import { useCacheUploadsParamsQuery, CacheUploadsParamsMainQuery } from '../../../queries/view-queries/useCacheUploadsParamsQuery';
import { InputSearchQueryKey, useSearchInputQuery } from '../../../queries/view-queries/useSearchInputQuery';
import { SearchInput } from '../../../components/UI/search-input';
import { DatePickerMainQueryKey, DatePickerQueryKey, useDatePickerQuery } from '../../../queries/view-queries/useDatePickerQuery';
import { useMissionTypeQuery } from '../../../queries/view-queries/useMissionTypeQuery';
import CarchupaDialog from '../../../components/UX/dialog/CarchupaDialog';
import Dropzone from '../../../components/UX/dropzone/Dropzone';
import { useCallbackWithLoading } from '../../../hooks/use-callback-with-loading';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { InvasiveSpeciesItem } from './InvasiveSpeciesItem';

export enum MissionType {
	video = 1,
	photo = 2,
	custom = 3,
	invasiveSpecies = 4
}

export function Uploads() {
	const { t, i18n } = useTranslation();

	const [selectMode, setSelectMode] = useState(false);
	const [open, setOpen] = useState(false);
	const [user, setUser] = useState('all');
	const [selectedUploads, setSelectedUploads] = useState<number[]>([]);
	const [allSelected, setAllSelected] = useState<boolean>(false);
	const [geoJsonModal, setGeoJsonModal] = useState(false);
	const [data, setData] = useState<any>([]);
	const [isDropzoneEmpty, setIsDropzoneEmpty] = useState(false);
	const [upload, uploadPending] = useCallbackWithLoading(API.uploadGeojson);
	const [searchedProjectName, setSearchedProjectName] = useState('');

	const canSubmit = useMemo(() => {
		return !!data?.length;
	}, [data]);

	const handleUnixTimestamp = (date: Date | null) => {
		return date ? Math.floor(new Date(date).getTime() / 1000) : undefined;
	};

	// Checks for all and returns undefined
	const handleUndefined = (forUnedifying: any) => {
		return forUnedifying === 'all' ? undefined : forUnedifying;
	};

	/////////////////////////////////////////////// REACT-QUERY ////////////////////////////////////////////////////////
	const { missionType, setMissionTypeQuery } = useMissionTypeQuery();
	const { getSelectedOption: getSelectedOptionForMission, setSelectedOption } = useSelectListQuery(MainQueryKey.mission, QueryKey.uploads);
	const { getSelectedOption: getSelectedOptionForFileStatus } = useSelectListQuery(MainQueryKey.fileStatus, QueryKey.uploads);
	const mission = getSelectedOptionForMission(MainQueryKey.mission, QueryKey.uploads);
	const fileStatus = getSelectedOptionForFileStatus(MainQueryKey.fileStatus, QueryKey.uploads);

	const { setPaginationData, getPaginationData } = usePaginationQueryClient(1);
	const { paginationPage } = usePaginationQuery(PaginationMainQueryKey.uploads, getPaginationData(PaginationMainQueryKey.uploads), 1);
	const { searchTerm } = useSearchInputQuery(InputSearchQueryKey.uploads);

	const { dates } = useDatePickerQuery(DatePickerMainQueryKey.dateRangePickerInput, DatePickerQueryKey.uploads);

	const uploadsParams = {
		status: handleUndefined(fileStatus),
		page: paginationPage,
		limit: 8,
		projectId: handleUndefined(mission),
		projectTypeId: missionType,
		userId: handleUndefined(user),
		startDate: handleUnixTimestamp(dates[0]),
		endDate: handleUnixTimestamp(dates[1]),
		username: searchTerm
	};

	const { setCacheUploadsParamsQuery } = useCacheUploadsParamsQuery(CacheUploadsParamsMainQuery.uploadPageParamsQueryKey);

	useEffect(() => {
		setCacheUploadsParamsQuery(CacheUploadsParamsMainQuery.uploadPageParamsQueryKey, uploadsParams);
	}, [fileStatus, paginationPage, mission, missionType, user, dates[0], dates[1], searchTerm]);

	const { data: projectListData } = useGetProjectListQuery({ fields: 'id,name', language: i18n.language });
	const { uploads, totalItems } = useGetUploadsData(uploadsParams);

	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const allApprovedUploadIds = uploads?.filter(upload => upload.status === 'approved').map(upload => upload.id) ?? [];

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setAllSelected(false);
		setSelectedUploads([]);

		setPaginationData(PaginationMainQueryKey.uploads, value);
	};

	const selectAll = () => {
		setSelectedUploads(uploads?.map(upload => upload.id));
		setAllSelected(true);
	};

	const onMissionClick = (missionType: MissionType) => {
		setMissionTypeQuery(missionType);
		setPaginationData(PaginationMainQueryKey.uploads, 1);
		setSelectedUploads([]);
	};

	const handleSelected = (isSelected: boolean, id: number) => {
		if (!isSelected) {
			setSelectedUploads((prevState: number[]) => [...prevState, id]);
		}
		if (isSelected) {
			setSelectedUploads(selectedUploads.filter((item: number) => item !== id));
		}
	};

	const uploadGeojson = useCallback(
		async (e: FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			if (!canSubmit) {
				return;
			} else {
				try {
					const forSending = new FormData();

					forSending.append('geojsonFile', data[0]);
					// canSubmit is checking that project is selected
					await upload(forSending)
						.then((res: any) => {
							toast.success(`${t('Upload successfull')}`);
							setGeoJsonModal(false);
						})
						.catch((error: any) => {
							console.error(`${t('Fail uploading to Database - ') + error?.message}`);
							setGeoJsonModal(false);
							console.error('Fail uploading json', error);
						});

					setData([]);
				} catch (error) {
					toast.error(`${t('Fail uploading coins')}`);
					console.error('Fail uploading coins', error);
					setData([]);
				}
			}
		},
		[canSubmit, data.selectedFiles, upload, t]
	);

	return (
		<SimpleLayout passedStyles="">
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Typography variant="h1">{t('uploads')}</Typography>
				<Button endIcon={<img alt="upload geojson" src={uploadIcon} />} onClick={() => setGeoJsonModal(true)} variant="text">
					{t('uploadGeoJson')}
				</Button>
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignContent: 'center',
					paddingBottom: '16px',
					flexWrap: 'wrap-reverse',
					gap: '5px'
				}}>
				<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
					<label htmlFor="mission">{t('missionType')}</label>
					<Box sx={{ display: 'flex', gap: '16px' }}>
						<Button
							onClick={() => onMissionClick(MissionType.video)}
							sx={{ whiteSpace: 'nowrap' }}
							variant={missionType === MissionType.video ? 'contained' : 'outlined'}>
							{t('videoMission')}
						</Button>
						<Button
							onClick={() => onMissionClick(MissionType.photo)}
							sx={{ whiteSpace: 'nowrap' }}
							variant={missionType === MissionType.photo ? 'contained' : 'outlined'}>
							{t('photoMission')}
						</Button>
						<Button
							onClick={() => onMissionClick(MissionType.custom)}
							sx={{ whiteSpace: 'nowrap' }}
							variant={missionType === MissionType.custom ? 'contained' : 'outlined'}>
							{t('customMission')}
						</Button>
						<Button
							onClick={() => onMissionClick(MissionType.invasiveSpecies)}
							sx={{ whiteSpace: 'nowrap' }}
							variant={missionType === MissionType.invasiveSpecies ? 'contained' : 'outlined'}>
							{t('invasiveSpeciesMission')}
						</Button>
					</Box>
				</Box>
				<Box sx={{ display: 'flex', gap: '24px' }}>
					<Box>
						<FormControl sx={{ width: '300px' }}>
							<label htmlFor="missionName">{t('missionName')}</label>
							<Autocomplete
								options={projectListData?.projects || []}
								noOptionsText={t('No options')}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								getOptionLabel={option => option.name}
								id="missionName"
								filterOptions={(options, { inputValue }) => {
									const filtered = options.filter(option => option.name.toLowerCase().includes(inputValue.trim().toLowerCase()));
									return filtered;
								}}
								renderOption={(props, item) => {
									return (
										<li {...props} key={item.id} value={item.id}>
											<div style={{ whiteSpace: 'normal', wordWrap: 'break-word', wordBreak: 'break-word' }} data-value={item.id}>
												{item.name}{' '}
												<sup data-value={item.id} style={{ color: 'grey' }}>
													({`ID: ${item.id}`})
												</sup>
											</div>
										</li>
									);
								}}
								onChange={(e: any) => {
									const value = e.target.value || e.target.getAttribute('data-value');
									setSelectedOption(MainQueryKey.mission, QueryKey.uploads, value);
								}}
								renderInput={params => (
									<TextField
										{...params}
										placeholder={t('all')}
										InputProps={{
											...params.InputProps,
											style: { alignContent: 'center' }, // Center text horizontally
											inputProps: {
												...params.inputProps,
												style: { alignContent: 'center', padding: '10px 0' } // Center text horizontally and vertically
											}
										}}
									/>
								)}
							/>
						</FormControl>
					</Box>

					<FormControl sx={{ width: '200px' }}>
						<label htmlFor="range">{t('dateRange')}</label>
						<DateRangePickerInput dates={dates} queryKey={DatePickerQueryKey.uploads} />
					</FormControl>
					<SelectList
						data={FileStatusesOption}
						htmlFor="user"
						id="user-select"
						labelId="user"
						labelText={t('fileStatus')}
						mainQueryKey={MainQueryKey.fileStatus}
						queryKey={QueryKey.uploads}
						sx={{ width: '200px' }}
					/>
					<FormControl sx={{ width: '300px', opacity: 0.8 }} variant="outlined">
						<SearchInput
							htmlFor="search"
							label={t('searchByUser')}
							placeholder={t('searchTextFieldPlaceholder')}
							id="search"
							paginationMainQueryKey={PaginationMainQueryKey.uploads}
							queryKey={InputSearchQueryKey.uploads}
						/>
					</FormControl>
				</Box>
			</Box>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '40px' }}>
				{selectMode ? (
					<>
						<Box sx={{ display: 'flex', gap: '16px' }}>
							<Button
								onClick={() => {
									setSelectMode(false);
									setSelectedUploads([]);
									setAllSelected(false);
								}}
								sx={{ padding: '0' }}
								variant="text">
								{t('Cancel')}
							</Button>
							<Button onClick={() => setOpen(true)} variant="contained">
								{t('bulkOptions')}
							</Button>
						</Box>
						{allSelected ? (
							<Button
								onClick={() => {
									setSelectedUploads([]);
									setAllSelected(false);
								}}
								sx={{ padding: '0' }}
								variant="text">
								{t('unselectAll')}
							</Button>
						) : (
							<Button onClick={selectAll} sx={{ padding: '0' }} variant="text">
								{t('selectAll')}
							</Button>
						)}
					</>
				) : (
					<Button
						onClick={() => setSelectMode(true)}
						sx={{ padding: '0' }}
						variant="text"
						style={{ display: missionType == MissionType.invasiveSpecies ? 'none' : '' }}>
						{t('selectFiles')}
					</Button>
				)}
			</Box>
			<Box sx={{ display: 'grid', gap: '24px', gridTemplateColumns: 'repeat(4, 1fr)' }}>
				{missionType === MissionType.video &&
					uploads?.map((video, index) => (
						<VideoItem
							allSelected={allSelected}
							handleSelected={handleSelected}
							isPhotoMission={false}
							selectMode={selectMode}
							uploadsParams={uploadsParams}
							video={video}
							// key={video.id}
							key={index}
						/>
					))}
				{missionType === MissionType.photo &&
					uploads?.map((video, index) => (
						<VideoItem
							allSelected={allSelected}
							handleSelected={handleSelected}
							isPhotoMission={true}
							key={index}
							photoUrl={video.url}
							selectMode={selectMode}
							uploadsParams={uploadsParams}
							video={video}
						/>
					))}
				{missionType === MissionType.custom &&
					// Custom missions in the future will need to support
					// Photo type uploads, and other upload types.
					// if >> upload == video type.
					uploads?.map((video, index) => (
						<VideoItem
							allSelected={allSelected}
							handleSelected={handleSelected}
							key={index}
							isPhotoMission={false}
							selectMode={selectMode}
							uploadsParams={uploadsParams}
							video={video}
						/>
					))}
				{missionType === MissionType.invasiveSpecies && uploads?.map((video, index) => <InvasiveSpeciesItem key={index} session={video} />)}
			</Box>
			<Box sx={{ float: 'right' }}>
				<Pagination
					count={Math.ceil(totalItems / 8)}
					onChange={handlePageChange}
					page={paginationPage}
					showFirstButton
					showLastButton
					sx={{ display: 'flex', justifyContent: 'center' }}
				/>
			</Box>
			<BulkOptionsModal
				uploads={uploads}
				selectedUploads={selectedUploads}
				allUploads={allApprovedUploadIds}
				open={open}
				setOpen={setOpen}
				missionType={missionType}
			/>
			<CarchupaDialog open={geoJsonModal} setOpen={setGeoJsonModal}>
				<form onSubmit={uploadGeojson}>
					<Box sx={{ padding: '24px 36px' }}>
						<Box sx={{ marginTop: '20px', paddingBottom: '12px', display: 'flex' }}>
							<InputLabel sx={{ color: '#494F4D' }}>{t('uploadGeoJson')}</InputLabel>
						</Box>
						<Dropzone
							error={isDropzoneEmpty}
							file={data}
							fileType=".geojson"
							multiple={false}
							setFile={setData}
							setIsDropzoneEmpty={setIsDropzoneEmpty}
						/>
					</Box>
					<Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
						<Button onClick={() => setGeoJsonModal(false)} sx={{ color: '#FE5F55' }}>
							{t('Cancel')}
						</Button>
						<Button type="submit" sx={{ color: 'primary' }} variant="contained" disabled={!canSubmit || uploadPending}>
							{t('Ok')}
						</Button>
					</Box>
				</form>
			</CarchupaDialog>
		</SimpleLayout>
	);
}
